import React, { useEffect, useState } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import api from "../../../../../CommonApi/axios";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import { useMediaQuery } from "react-responsive";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import headers from "../../../../../CommonApi/headers";
import BarChart from "./Chart/BarChart";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Box, Typography } from "@mui/material";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from '@mui/material/Backdrop';
import useDrawer from "../../../../../context/DrawerContext";
import { ErrorMessage } from "../../../../Comman/Error/ErrorMessage";
import useError from "../../../../Comman/Error/useError";
import { getCurrencyLabel } from "../../../../Comman/currencyUtils/currencyUtils";
import { selectStylesReport } from "../../../../Comman/CommonFunction/CommonFunction";
import { connect } from "react-redux";
import { getBranchList } from "../../../../../Store";
import { branchfilterAndMapData, getSelectOptions } from "../../../../Comman/BranchList";
import { setMaxDate } from "../../../../Comman/DateUtils/dateUtils";
import * as XLSX from 'xlsx';
import capitalizeFirstLetter from "../../../../Comman/UpperCase";
import BranchCharts from "./Chart/BarChart";




function GroupAdminTableReservations({ getBranchList, BranchList_ }) {

      const { error, showError, clearError } = useError();

      let history = useHistory();
      const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
      const filteredOptions = branchfilterAndMapData(BranchList_);


      const [start_date, setstart_date] = useState("");
      const [end_date, setend_date] = useState(new Date());
      const [is_present, setis_present] = useState(false);
      const [branch_id, setbranch_id] = useState("");
      const [branch_id2, setbranch_id2] = useState([]);
      const [Order_type, setOrder_type] = useState("");
      const [Order_type2, setOrder_type2] = useState([]);
      const [loader, setLoader] = useState(false);
      const [Dowloader, setDowloader] = useState(false);
      const [PDFLogo, setPDFLogo] = useState("");
      const [DataReport, setDataReport] = useState([]);

      const currencyLabel = getCurrencyLabel(branch_id2 && branch_id2?.currency);
      // console.log(Order_type)

      useEffect(() => {
            getBranchList();
            //  setMaxDate(["date", "date1"]); //// Max date validation ////
      }, []);

      const handleConvertToPdf = async () => {
            setDowloader(true);

            const doc = new jsPDF();

            // Set PDF properties
            doc.setProperties({
                  title: 'My PDF Document',
                  subject: 'Branch-wise Report',
                  author: 'Your Name',
                  keywords: 'PDF, report, branch, table',
            });

            let yPosition = 20; // Initial vertical position for the content


            if (Order_type === "Best selling products by orders") {
                  for (const branch of DataReport) {
                        // Skip branches with no order items
                        if (!branch?.order_items || branch.order_items.length === 0) {
                              continue; // Skip to the next branch if no data is available
                        }

                        const tableData = [];

                        // Add the table header row
                        tableData.push(['Product name', 'Quantity', 'Amount']);

                        // Add data from order items
                        branch?.order_items?.forEach((item) => {
                              tableData.push([
                                    item.name_EN,
                                    Number(item.total_quantity),
                                    Number(item.total_price),  // Use total_price for the table
                              ]);
                        });

                        // Calculate total amount for this branch
                        const totalAmount = branch?.order_items?.reduce((sum, item) => sum + Number(item.total_price), 0);  // Corrected to use total_price

                        // Display branch name and other info
                        doc.setFont("Inter");
                        doc.setFontSize(12);
                        doc.text(`Branch: ${branch.branch_name_EN}`, 15, yPosition);
                        yPosition += 6;
                        doc.text(`Address : ${branch.address}`, 15, yPosition);
                        yPosition += 6;
                        doc.text(`Currency : ${branch.currency}`, 15, yPosition);
                        yPosition += 6;
                        doc.text(Order_type, 15, yPosition);
                        yPosition += 6;
                        doc.text(
                              `Date: ${moment(start_date).format("DD.MM.YYYY")} - ${moment(end_date || new Date()).format("DD.MM.YYYY")}`,
                              15, yPosition
                        );
                        yPosition += 12;

                        // Draw border on every page
                        doc.setDrawColor(0); // Set border color (black)
                        doc.setLineWidth(0.3); // Set border line width
                        doc.rect(10, 10, doc.internal.pageSize.getWidth() - 20, doc.internal.pageSize.getHeight() - 20);

                        // Define table styling and add table for the branch
                        doc.autoTable({
                              head: [tableData[0]], // Table header
                              body: tableData.slice(1), // Table rows
                              startY: yPosition,
                              theme: 'grid',
                              headStyles: {
                                    fillColor: [192, 192, 192], // Header background color
                                    textColor: [0, 0, 0], // Header text color
                              },
                              margin: { left: 15 },
                              styles: { fontSize: 10 },
                        });

                        // Update yPosition based on the table's finalY
                        yPosition = doc.autoTable.previous.finalY + 10;

                        // Display total amount below the table
                        doc.text(`Total Amount: ${Number(totalAmount || 0)}`, 155, yPosition, { align: 'left' }); // Format total amount
                        yPosition += 10;

                        // Add a page break if content reaches near the page's end
                        if (yPosition > doc.internal.pageSize.getHeight() - 30) {
                              doc.addPage();
                              yPosition = 20; // Reset yPosition for new page
                        }
                  }
            } else if (Order_type === "Order by order type") {
                  for (const branch of DataReport) {
                        // Skip branches with no order items
                        if (!branch?.order_list || branch?.order_list?.length === 0) {
                              continue; // Skip to the next branch if no data is available
                        }

                        const tableData = [];

                        // Add the table header row
                        tableData.push(["Order type", "Order count", "Total amount", "Total discount", "Final amount"]);

                        // // Add data from order items
                        // branch?.order_list?.forEach((item) => {
                        //     tableData.push([
                        //         item.name_EN,
                        //         Number(item.total_quantity),
                        //         Number(item.total_price),  // Use total_price for the table
                        //     ]);
                        // });

                        branch?.order_list?.forEach(order => {
                              const { order_type, order_details } = order;

                              // Helper function to get values based on order_type
                              const getDetail = (totalField, regularField) =>
                                    Number(order_type === "Total" ? (order_details?.[totalField] || 0) : (order_details?.[regularField] || 0));

                              const orderRow = [
                                    capitalizeFirstLetter(order_type),
                                    getDetail('total_order_count', 'order_count'),
                                    getDetail('total_gross_total', 'gross_total'),
                                    getDetail('total_total_discount', 'total_discount'),
                                    getDetail('total_gross_total_with_tax', 'gross_total_with_tax')
                              ];

                              tableData.push(orderRow);
                        });

                        // Calculate total amount for this branch
                        const totalAmount = branch?.order_list?.reduce((sum, item) => sum + Number(item.total_price), 0);  // Corrected to use total_price

                        // Display branch name and other info
                        doc.setFont("Inter");
                        doc.setFontSize(12);
                        doc.text(`Branch: ${branch?.branch_name}`, 15, yPosition);
                        yPosition += 6;
                        doc.text(`Address : ${branch?.branch_address}`, 15, yPosition);
                        yPosition += 6;
                        doc.text(`Currency : ${branch?.currency}`, 15, yPosition);
                        yPosition += 6;
                        doc.text(Order_type, 15, yPosition);
                        yPosition += 6;
                        doc.text(
                              `Date: ${moment(start_date).format("DD.MM.YYYY")} - ${moment(end_date || new Date()).format("DD.MM.YYYY")}`,
                              15, yPosition
                        );
                        yPosition += 12;

                        // Draw border on every page
                        doc.setDrawColor(0); // Set border color (black)
                        doc.setLineWidth(0.3); // Set border line width
                        doc.rect(10, 10, doc.internal.pageSize.getWidth() - 20, doc.internal.pageSize.getHeight() - 20);

                        // Define table styling and add table for the branch
                        doc.autoTable({
                              head: [tableData[0]], // Table header
                              body: tableData.slice(1), // Table rows
                              startY: yPosition,
                              theme: 'grid',
                              headStyles: {
                                    fillColor: [192, 192, 192], // Header background color
                                    textColor: [0, 0, 0], // Header text color
                              },
                              margin: { left: 15 },
                              styles: { fontSize: 10 },
                        });

                        // Update yPosition based on the table's finalY
                        yPosition = doc.autoTable.previous.finalY + 10;

                        // Display total amount below the table
                        // doc.text(`Total Amount: ${Number(totalAmount || 0)}`, 155, yPosition , { align: 'left' }); // Format total amount
                        // yPosition += 10;

                        // Add a page break if content reaches near the page's end
                        if (yPosition > doc.internal.pageSize.getHeight() - 30) {
                              doc.addPage();
                              yPosition = 20; // Reset yPosition for new page
                        }
                  }
            } else if (Order_type === "Ordered products by date") {

                  for (const branch of DataReport) {
                        // Skip branches with no order items
                        if (!branch?.order_details || branch?.order_details?.length === 0) {
                              continue; // Skip to the next branch if no data is available
                        }

                        const tableData = [];

                        tableData.push(["Order no", "Order date", "Order type", "Products", "Price", "Discount amount", "Final amount"]);

                        branch?.order_details?.forEach(order => {
                              const { order_type, unique_code, total_amount, discount_amount, order_date, order_items, } = order;

                              const dateSplit = order_date.split("T")
                              // const abc = moment(dateSplit[0]).format("DD/MM/YYYY")
                              // console.log("abc" ,abc)
                              const orderRow = [
                                    unique_code,
                                    moment(dateSplit[0]).format("DD/MM/YYYY"),
                                    capitalizeFirstLetter(order_type),
                                    order_items?.map((main) => `${main?.item_name} (${main?.total_quantity})`),
                                    Number(total_amount || 0),
                                    Number(discount_amount || 0),
                                    Number(total_amount - discount_amount || 0)
                              ];

                              tableData.push(orderRow);
                        });

                        // Display branch name and other info
                        doc.setFont("Inter");
                        doc.setFontSize(12);
                        doc.text(`Branch : ${branch.branch_name_EN}`, 15, yPosition);
                        yPosition += 6;
                        doc.text(`Address : ${branch.address}`, 15, yPosition);
                        yPosition += 6;
                        doc.text(`Currency : ${branch.currency}`, 15, yPosition);
                        yPosition += 6;
                        doc.text(Order_type, 15, yPosition);
                        yPosition += 6;
                        doc.text(
                              `Date: ${moment(start_date).format("DD.MM.YYYY")} - ${moment(end_date || new Date()).format("DD.MM.YYYY")}`,
                              15, yPosition
                        );
                        yPosition += 12;

                        // Draw border on every page
                        doc.setDrawColor(0); // Set border color (black)
                        doc.setLineWidth(0.3); // Set border line width
                        doc.rect(10, 10, doc.internal.pageSize.getWidth() - 20, doc.internal.pageSize.getHeight() - 20);

                        // Define table styling and add table for the branch
                        doc.autoTable({
                              head: [tableData[0]], // Table header
                              body: tableData.slice(1), // Table rows
                              startY: yPosition,
                              theme: 'grid',
                              headStyles: {
                                    fillColor: [192, 192, 192], // Header background color
                                    textColor: [0, 0, 0], // Header text color
                              },
                              margin: { left: 15 },
                              styles: { fontSize: 10 },
                        });

                        yPosition = doc.autoTable.previous.finalY + 10;

                        if (yPosition > doc.internal.pageSize.getHeight() - 30) {
                              doc.addPage();
                              yPosition = 20; // Reset yPosition for new page
                        }
                  }
            }

            // Save the PDF after processing all branches
            // const fileName = `${Order_type} Report ${moment(new Date()).format("DD-MM-YYYY")}.pdf`;
            const fileName = `${Order_type?.replace(/ /g, "_")}_${moment(new Date()).format("DD_MM_YYYY")}.pdf`;
            doc.save(fileName);
            setDowloader(false);
      };


      function handleSubmit(event) {
            event.preventDefault();
            clearError(null);
            setDataReport([]);

            // Validate required fields
            if (!branch_id) {
                  showError("Please select branch.");
                  return;
            }
            if (!Order_type) {
                  showError("Please select order type.");
                  return;
            }
            if (!start_date) {
                  showError("Please select start date.");
                  return;
            }

            // Check date logic
            const endDateFormatted = moment(end_date).add(1, "days").format("YYYY-MM-DD");
            const startDateFormatted = moment(start_date).format("YYYY-MM-DD");

            if (Date.parse(end_date) && Date.parse(endDateFormatted) <= Date.parse(start_date)) {
                  showError("End date should be greater than Start date");
                  return;
            }

            setLoader(true);
            // Define the basePath dynamically based on Order_type
            let basePath;
            switch (Order_type) {
                  case "Best selling products by orders":
                        basePath = "/groupadmin/report/bestSellingProduct";
                        fetchBestSellingProducts(basePath, branch_id, startDateFormatted, endDateFormatted);
                        break;

                  case "Order by order type":
                        basePath = "/groupadmin/report/orderByOrderType";
                        fetchBestSellingProducts(basePath, branch_id, startDateFormatted, endDateFormatted);
                        break;

                  case "Ordered products by date":
                        basePath = "/groupadmin/report/downloadOrderList";
                        fetchBestSellingProducts(basePath, branch_id, startDateFormatted, endDateFormatted);
                        break;
                  default:
                        setLoader(false);
                        break;
            }
      }

      function fetchBestSellingProducts(basePath, branch_id, startDateFormatted, endDateFormatted) {
            let url = `${basePath}?start_date=${startDateFormatted}`;

            if (branch_id !== "All") {
                  url += `&branch_id=${branch_id}`;
            }
            if (endDateFormatted) {
                  url += `&end_date=${endDateFormatted}`;
            }

            api.get(url, { headers })
                  .then((res) => {
                        setLoader(false);
                        // console.log("res" ,res?.data?.data)
                        // !branch?.order_list || branch?.order_list?.length === 0
                        // console.log("res", res?.data?.data?.map((main)=>{
                        //   main?.filter((mian1) => !mian1?.order_list || mian1?.order_list?.length === 0)
                        // }))
                        if (Order_type === "Best selling products by orders") {
                              const res_ = res?.data?.data?.filter(branch => branch?.order_items?.length > 0)
                              setDataReport(res_ || []);
                              setis_present(res_?.length > 0 ? false : true);
                        } else if (Order_type === "Order by order type") {
                              const res_ = res?.data?.data.filter(branch => branch?.order_list?.length > 0)
                              setDataReport(res_ || [])
                              setis_present(res_?.length > 0 ? false : true);
                        } else if (Order_type === "Ordered products by date") {
                              const res_ = res?.data?.data?.branchData?.filter(branch => branch.order_details?.length > 0)
                              console.log("res_", res_)
                              setDataReport(res_ || [])
                              setis_present(res_?.length > 0 ? false : true);
                        }
                        setPDFLogo(res?.data?.data?.logo_base64 || "");
                  })
                  .catch((err) => {
                        setLoader(false);
                        if (err.response) {
                              setis_present(true);
                              // showError(err.response?.data?.error?.message);
                        }
                  });
      }



      const handleClickCSVDownload = () => {
            const fileName = `${Order_type?.replace(/ /g, "_")}.xlsx`;

            if (!Array.isArray(DataReport)) {
                  console.error("DataReport is not an array:", DataReport);
                  return;
            }

            if (Order_type === "Best selling products by orders") {
                  const wb = XLSX.utils.book_new();

                  DataReport.forEach(branch => {

                        if (!branch?.order_items || branch?.order_items.length === 0) return; // Skip branches without orders

                        const Header1 = [{
                              v: `Generated by Gurjinder Singh ${(moment(new Date()).format('DD-MM-YYYY h:mm:ss a'))}. All Figures in ${branch?.currency} except where noted`,
                        }];
                        const Header4 = [];
                        const Header8 = [];
                        const secondRowHeader = ["Product name", "Quantity", "Amount"];

                        const Header2 = [`${branch?.address} - ${branch?.branch_name_EN}`];
                        const Header6 = [`Start date : ${moment(start_date).format("DD/MM/YYYY")}`];
                        const Header7 = [`End date : ${moment(end_date).format("DD/MM/YYYY")}`];

                        const wsData = [
                              Header1, Header2, Header4, Header6, Header7, Header8, secondRowHeader
                        ];

                        branch.order_items.forEach(order => {
                              const { name_EN, total_price, total_quantity } = order;
                              const orderRow = [name_EN, Number(total_quantity), Number(total_price)];
                              wsData.push(orderRow);
                        });

                        // Create a worksheet for the current branch
                        const ws = XLSX.utils.aoa_to_sheet(wsData);

                        // Add the worksheet to the workbook with branch name as sheet name
                        XLSX.utils.book_append_sheet(wb, ws, branch.branch_name_EN || "Branch");
                  });
                  // Save the workbook
                  XLSX.writeFile(wb, fileName);
            } else if (Order_type === "Order by order type") {
                  const wb = XLSX.utils.book_new();

                  DataReport.forEach(branch => {

                        if (!branch?.order_list || branch?.order_list.length === 0) return; // Skip branches without orders

                        const Header1 = [{
                              v: `Generated by Gurjinder Singh ${(moment(new Date()).format('DD-MM-YYYY h:mm:ss a'))}. All Figures in ${branch?.currency} except where noted`,
                        }];
                        const Header4 = [];
                        const Header8 = [];
                        const secondRowHeader = ["Order type", "Order count", "Total amount", "Total discount", "Final amount"];

                        const Header2 = [`${branch?.branch_address} - ${branch?.branch_name}`];
                        const Header6 = [`Start date : ${moment(start_date).format("DD/MM/YYYY")}`];
                        const Header7 = [`End date : ${moment(end_date).format("DD/MM/YYYY")}`];

                        const wsData = [
                              Header1, Header2, Header4, Header6, Header7, Header8, secondRowHeader
                        ];

                        branch?.order_list?.forEach(order => {
                              const { order_type, order_details } = order;

                              // Helper function to get values based on order_type
                              const getDetail = (totalField, regularField) =>
                                    Number(order_type === "Total" ? (order_details?.[totalField] || 0) : (order_details?.[regularField] || 0));

                              const orderRow = [
                                    capitalizeFirstLetter(order_type),
                                    getDetail('total_order_count', 'order_count'),
                                    getDetail('total_gross_total', 'gross_total'),
                                    getDetail('total_total_discount', 'total_discount'),
                                    getDetail('total_gross_total_with_tax', 'gross_total_with_tax')
                              ];

                              wsData.push(orderRow);
                        });

                        // Create a worksheet for the current branch
                        const ws = XLSX.utils.aoa_to_sheet(wsData);

                        // Merge cells B10 and B11 (row 9 and row 10, column 1)
                        // ws['!merges'] = ws['!merges'] || [];
                        // ws['!merges'].push({ s: { r: 9, c: 1 }, e: { r: 10, c: 1 } });  //

                        // Add the worksheet to the workbook with branch name as sheet name
                        XLSX.utils.book_append_sheet(wb, ws, branch.branch_name || "Branch");
                  });
                  // Save the workbook
                  XLSX.writeFile(wb, fileName);
            } else if (Order_type === "Ordered products by date") {
                  const wb = XLSX.utils.book_new();

                  DataReport.forEach(branch => {
                        if (!branch?.order_details || branch?.order_details.length === 0) return; // Skip branches without orders

                        const Header1 = [{
                              v: `Generated by Gurjinder Singh ${moment(new Date()).format('DD-MM-YYYY h:mm:ss a')}. All Figures in ${branch?.currency} except where noted`,
                        }];
                        const Header2 = [`${branch?.address} - ${branch?.branch_name_EN}`];
                        const secondRowHeader = ["Order no", "Order date", "Order type", "Products", "Price", "Discount amount", "Final amount"];

                        const wsData = [Header1, Header2, [], [], secondRowHeader];

                        branch?.order_details?.forEach(order => {
                              const { order_type, unique_code, total_amount = 0, discount_amount = 0, order_date, order_items } = order;
                              const formattedOrderItems = order_items?.map(item => `${item?.item_name} (${item?.total_quantity})`).join(", ");


                              const dateSplit = order_date.split("T")

                              const orderRow = [
                                    unique_code,
                                    moment(dateSplit[0]).format("DD/MM/YYYY"),
                                    capitalizeFirstLetter(order_type),
                                    formattedOrderItems,
                                    total_amount,
                                    discount_amount,
                                    total_amount - discount_amount
                              ];

                              wsData.push(orderRow);
                        });

                        // Create a worksheet for the current branch
                        const ws = XLSX.utils.aoa_to_sheet(wsData);

                        // Add the worksheet to the workbook with branch name as sheet name
                        XLSX.utils.book_append_sheet(wb, ws, branch?.branch_name_EN || "Branch");
                  });

                  // Save the workbook
                  XLSX.writeFile(wb, fileName);
            }

      }


      const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();



      return (
            <React.Fragment>
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div
                                          className="wrapper d-flex flex-column flex-row-fluid"
                                          id="kt_wrapper"
                                    >
                                          <GroupAdminHeader />

                                          <div
                                                className=" d-flex flex-column flex-column-fluid"
                                                id="kt_content"
                                          >
                                                <div id="kt_content_container" className={isMobile ? "mx-5 p-0" : "mx-7 p-0"} >
                                                      <main style={{ position: "initial" }} className={mainClassCustome.join(" ")}>

                                                            <div className="d-flex flex-stack mb-2 p-0">
                                                                  <div
                                                                        className="d-flex justify-content-end"
                                                                        data-kt-docs-table-toolbar="base"
                                                                  >
                                                                        <h2
                                                                              className="anchor fw-bolder mb-5"
                                                                              id="custom-form-control"
                                                                        >
                                                                              Table Reservation Reports
                                                                        </h2>
                                                                  </div>
                                                                  <div className="d-flex flex-stack mb-5">
                                                                        <div
                                                                              className="d-flex justify-content-end mx-2"
                                                                              data-kt-docs-table-toolbar="base"
                                                                        >
                                                                              <button
                                                                                    onClick={() => history.push("/group_admin/report")}
                                                                                    className="btn btn-primary ps-7"
                                                                              >
                                                                                    Go Back
                                                                              </button>
                                                                        </div>
                                                                  </div>
                                                            </div>

                                                            <div className="py-2">
                                                                  <Card style={{ border: "1px solid #bdbcbc" }}>
                                                                        <Card.Body>
                                                                              <Row >
                                                                                    <Col lg={2} md={2} sx={2}>
                                                                                          <label className="required form-label">
                                                                                                Select Branch
                                                                                          </label>
                                                                                          <Select
                                                                                                styles={selectStylesReport}
                                                                                                className="search-line"
                                                                                                placeholder="Search..."
                                                                                                options={getSelectOptions(filteredOptions)}
                                                                                                value={branch_id2}
                                                                                                onChange={(opt) => {
                                                                                                      if (opt.value === "All") {
                                                                                                            setbranch_id(opt.value);
                                                                                                      } else {
                                                                                                            setbranch_id(opt.value?.value);
                                                                                                      }
                                                                                                      setbranch_id2(opt);
                                                                                                      setDataReport([]);
                                                                                                      setis_present(false);
                                                                                                }}
                                                                                          />
                                                                                    </Col>

                                                                                    <Col lg={2} md={2} sx={2}>
                                                                                          <label className="required form-label">
                                                                                                Select Request Type
                                                                                          </label>
                                                                                          <Select
                                                                                                styles={selectStylesReport}
                                                                                                className="search-line"
                                                                                                placeholder="Search..."
                                                                                                options={[
                                                                                                      {
                                                                                                            label: "Website",
                                                                                                            value: "Website",
                                                                                                      },
                                                                                                      {
                                                                                                            label: "By Call",
                                                                                                            value: "By Call",
                                                                                                      },
                                                                                                      {
                                                                                                            label: "Walk-in",
                                                                                                            value: "Walk-in",
                                                                                                      },


                                                                                                ]}
                                                                                                value={Order_type2}
                                                                                                onChange={(opt) => {
                                                                                                      setDataReport([]);
                                                                                                      setOrder_type(opt.value);
                                                                                                      setOrder_type2(opt);
                                                                                                      setis_present(false);
                                                                                                }}
                                                                                          /></Col>

                                                                                    <Col lg={2} md={2} sx={2}>
                                                                                          <label className="required form-label">
                                                                                                Select Order Type
                                                                                          </label>
                                                                                          <Select
                                                                                                styles={selectStylesReport}
                                                                                                className="search-line"
                                                                                                placeholder="Search..."
                                                                                                options={[
                                                                                                      {
                                                                                                            label: "Delivery",
                                                                                                            value: "delivery",
                                                                                                      },
                                                                                                      {
                                                                                                            label: "Pickup",
                                                                                                            value: "pickup",
                                                                                                      },
                                                                                                      {
                                                                                                            label: "DineIn",
                                                                                                            value: "dineIn",
                                                                                                      },


                                                                                                ]}
                                                                                                value={Order_type2}
                                                                                                onChange={(opt) => {
                                                                                                      setDataReport([]);
                                                                                                      setOrder_type(opt.value);
                                                                                                      setOrder_type2(opt);
                                                                                                      setis_present(false);
                                                                                                }}
                                                                                          /></Col>

                                                                                    <Col lg={2} md={2} sx={2}>
                                                                                          <label className="required form-label">
                                                                                                Start Date
                                                                                          </label>
                                                                                          <input
                                                                                                type="date"
                                                                                                name="date"
                                                                                                id="date"
                                                                                                className="form-control"
                                                                                                value={start_date}
                                                                                                onChange={(e) => {
                                                                                                      setstart_date(e.target.value);
                                                                                                      setDataReport([]);
                                                                                                }}
                                                                                          />
                                                                                    </Col>
                                                                                    <Col lg={2} md={2} sx={2}>
                                                                                          <label className="form-label">End Date</label>
                                                                                          <input
                                                                                                type="date"
                                                                                                name="date1"
                                                                                                id="date1"
                                                                                                className="form-control"
                                                                                                value={end_date}
                                                                                                onChange={(e) => {
                                                                                                      setend_date(e.target.value);
                                                                                                      setDataReport([]);
                                                                                                }}
                                                                                          />
                                                                                    </Col>

                                                                              </Row>

                                                                              <div className="row mt-7" >


                                                                                    <div className={isTabletOrMobile ? "col-md-4 mt-8 text-end" : "col-md-3 mt-8"} >
                                                                                          {loader ? (
                                                                                                <Box>
                                                                                                      <CircularProgress />
                                                                                                </Box>
                                                                                          ) : (
                                                                                                <button
                                                                                                      onClick={(e) => handleSubmit(e)}
                                                                                                      type="button"
                                                                                                      className="btn btn-secondary"
                                                                                                >
                                                                                                      <span className="indicator-label">
                                                                                                            <i className="fa fa-eye fs-2"></i>&nbsp;
                                                                                                            Preview</span>
                                                                                                </button>
                                                                                          )}

                                                                                    </div>
                                                                                    <div className={isTabletOrMobile ? "col-md-12 mt-8 text-end" : "col-md-5 mt-9 text-end"}>
                                                                                          {DataReport?.length > 0 &&
                                                                                                <React.Fragment>
                                                                                                      {Dowloader ?
                                                                                                            <Backdrop
                                                                                                                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                                                                                                  open={true}
                                                                                                            >
                                                                                                                  <CircularProgress color="inherit" />
                                                                                                            </Backdrop> :
                                                                                                            <button
                                                                                                                  onClick={handleConvertToPdf}
                                                                                                                  type="button"
                                                                                                                  className="btn btn-secondary"
                                                                                                            >
                                                                                                                  <span className="indicator-label">
                                                                                                                        <i className="fa fa-file-pdf-o fs-3"></i>&nbsp;Download PDF</span>
                                                                                                            </button>}
                                                                                                      &nbsp;&nbsp;
                                                                                                      <button
                                                                                                            onClick={handleClickCSVDownload}
                                                                                                            type="button"
                                                                                                            className="btn btn-secondary"
                                                                                                      >
                                                                                                            <span className="indicator-label">

                                                                                                                  <i className="fa fa-file-excel-o fs-3"></i>&nbsp;Download CSV</span>
                                                                                                      </button>
                                                                                                </React.Fragment>}
                                                                                    </div>
                                                                              </div>
                                                                              <br />
                                                                              <ErrorMessage error={error} />

                                                                        </Card.Body>
                                                                  </Card>

                                                                  {/* <div>
                          <div id="contentToConvert" >
                            {Order_type === "Best selling products by orders" &&
                              <BranchCharts currencyLabel={currencyLabel} data={DataReport} />
                            }
                          </div>
                        </div> */}

                                                                  {is_present &&
                                                                        <Card style={{ border: "1px solid rgb(189, 188, 188)" }} className="mt-10 my-4 text-center">
                                                                              <Row className="my-5 text-center">
                                                                                    <Col className="my-4">
                                                                                          <h5>
                                                                                                No Data for the selected Date or Filter. Please select a different Date or Filter.
                                                                                          </h5>
                                                                                    </Col>
                                                                              </Row>
                                                                        </Card>}

                                                            </div>
                                                      </main>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                              <GroupAdminUperSide />
                        </div>
                  </div>

                  <ToastContainer />
            </React.Fragment>
      );
}

const mapStateToProps = (state) => ({
      BranchList_: state.branchList,
});

const mapDispatchToProps = (dispatch) => {
      return {
            getBranchList: () => dispatch(getBranchList()),
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminTableReservations);