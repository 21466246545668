import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import GroupAdminHeader from "../../Comman/Header";
import GroupAdminSidebar from "../../Comman/Sidebar";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import { useEffect } from "react";
// import $ from "jquery";
import { showSuccessNotification } from "../../Comman/Swal/Swal";
import { applyFilterMeHandlers } from "../../Comman/Error/jqueryUtils";
import useDrawer from "../../../context/DrawerContext";
import { colourStylesHeader } from "../Component/Table/AddTableReservation/common";
import Select from "react-select";
import useError from "../../Comman/Error/useError";
import { ErrorMessage } from "../../Comman/Error/ErrorMessage";
import { getAreaList } from "../../../Store";
import { connect } from "react-redux";
import { LoadingBackdrop } from "../../Comman/LoadingBackdrop/LoadingBackdrop";

// window.jquery = window.$ = $;

function GroupAdminAddTable({ getAreaList, arealist }) {
  let history = useHistory();
  let id = useParams();
  const [loading, setloading] = useState(false)

  useEffect(() => {
    applyFilterMeHandlers(); // Apply the jQuery handlers
  }, []);

  const BranchId = id?.id

  useEffect(() => {
    if (BranchId) {
      getAreaList(BranchId);
    }
  }, [BranchId]);

  // console.log("id", id)

  const { error, showError, clearError } = useError();

  var pathname = window.location.pathname;
  var url = pathname.split("/")
  var NewTableNumber = Number(url[5]);

  // const [data, setForm] = useState([...Array(NewTableNumber)]?.map((_, i) => {
  //   return {table_number : i + 1 , pos_table_number : ""} ;
  // }));

  const [data, setForm] = useState(
    [...Array(Number(NewTableNumber))]?.map((_, i) => {
      // return  {table_number : i + Number(OldTableNumber > 0 ? OldTableNumber + 1 : 1)  , pos_table_number : ""} ;
      return { table_number: "", pos_table_number: "", no_of_chairs: 0, area_id: "", order_type: "" };
    }));


  const handleCheck = (index, e, selected) => {
    let temp = [...data];
    const newValue = e.target.value; // Store the new value

    temp[index][selected] = newValue;
    setForm(temp);
    // console.log("temp" ,temp)
  };

  const handleCheckSelect = (index, e, selected) => {
    let temp = [...data];
    temp[index][selected] = e; // Store the full object { value, label }
    setForm(temp);
  };



  // console.log("data", data);

  function handleSubmit(event) {
    event.preventDefault();
    clearError(null);
    setloading(true);
    for (let i = 0; i < data.length; i++) {

      if (data[i].pos_table_number == "") {
        showError("Please enter table number ");
        setloading(false);
        return;
      }
      else if (data[i].pos_table_number == 0) {
        showError("Table number does not enter zero.");
        setloading(false);
        return;
      }
      else if (data[i].order_type === "") {
        showError("Please select order type.");
        setloading(false);
        return;
      }
      else if (data[i].area_id === "") {
        showError("Please select table area/floor.");
        setloading(false);
        return;
      }
      else if (
        data[i].order_type?.value === "dineIn" &&
        (!data[i].no_of_chairs || Number(data[i].no_of_chairs) <= 0)
      ) {
        showError("Please enter a valid number of chairs for dineIn.");
        setloading(false);
        return;
      }
    }


    let item = {
      branch_id: id.id,
      tableData: data.map((item) => ({
        table_number: item?.pos_table_number, // Copy pos_table_number to table_number
        pos_table_number: item?.pos_table_number, // Keep pos_table_number as is
        no_of_chairs: Number(item?.no_of_chairs),
        area_id: item?.area_id?.value,
        order_type: item?.order_type?.value
      })),
    }

    // console.log("item" ,item);
    // return

    api
      .post(`/groupadmin/table/add`, item, { headers })
      .then((res) => {
        setloading(false);
        showSuccessNotification("Table has been created succesfully.");
        history.push(`/group_admin/branch_management/table_management/${id.id}`);
      })
      .catch((err) => {
        if (err.response) {
          setloading(false);
          showError(err.response?.data?.error?.message);
        } else if (err.request) {
          showError(err.request);
        } else {
          showError(err.message);
        }
      });
  }

  const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main style={{ position: "initial", marginTop: "-27px" }} className={`main_ ${mainClassCustome.join(" ")}`}>
                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h1
                          className="anchor fw-bolder"
                          id="custom-form-control"
                        >
                          Add Table
                        </h1>
                      </div>
                      <div className="d-flex flex-stack ">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <button
                            onClick={() => history.push(`/group_admin/branch_management/table_management/${id.id}`)}
                            className="btn btn-primary"
                            data-kt-menu-trigger="click"
                            data-kt-menu-attach="parent"
                            data-kt-menu-placement="bottom-end"
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="py-5">
                      <div className="card card-docs mb-2">
                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                          <div className="rounded border p-10">
                            <form onSubmit={handleSubmit} >
                              {NewTableNumber && (
                                <div className="row col-md-12">
                                  {data.map((details, index) => (
                                    <React.Fragment key={index}>
                                      <div className="col-md-6" style={{ display: "none" }}>
                                        <div className="mb-10">
                                          <label className="required form-label">
                                            Table Number
                                          </label>
                                          <input
                                            type="text"
                                            className="filterme form-control form-control"
                                            placeholder="Table Number 1"
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-3">
                                        <div className="mb-10">
                                          <label className="required form-label">
                                            Table Number
                                          </label>
                                          <input
                                            type="text"
                                            className="filterme form-control form-control"
                                            placeholder="Table Number"
                                            value={details?.pos_table_number}
                                            onChange={(e) =>
                                              handleCheck(
                                                index,
                                                e,
                                                "pos_table_number"
                                              )
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-3">
                                        <div className="mb-10">
                                          <label className="required form-label">
                                            Order Type
                                          </label>
                                          <Select
                                            className="search-line"
                                            placeholder="Select Order Type"
                                            styles={colourStylesHeader}
                                            options={[
                                              { value: "delivery", label: "Delivery" },
                                              { value: "pickup", label: "Pickup" },
                                              { value: "dineIn", label: "DineIn" },
                                            ]}
                                            value={details.order_type} // No need to manually format it
                                            onChange={(e) => handleCheckSelect(index, e, "order_type")}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-3">
                                        <div className="mb-10">
                                          <label className="required form-label">
                                            Table Area/Floor
                                          </label>
                                          <Select
                                            className="search-line"
                                            placeholder="Select Area/Floor"
                                            options={arealist?.length > 0 ? arealist?.map((main) => ({
                                              label: main?.area_name,
                                              value: main?._id
                                            })) : []}
                                            styles={colourStylesHeader}
                                            value={details.area_id} // No need to manually format it
                                            onChange={(e) => handleCheckSelect(index, e, "area_id")}
                                          />
                                        </div>
                                      </div>



                                      <div className="col-md-3">
                                        <div className="mb-10">
                                          <label className={details?.order_type?.value === "dineIn" ? "required form-label" : "form-label"} >
                                            No. Of Chairs
                                          </label>
                                          <input
                                            type="text"
                                            disabled={details?.order_type?.value === "dineIn" ? false : true}
                                            className="filterme form-control form-control"
                                            placeholder="Enter No. Of Chairs"
                                            value={details?.no_of_chairs}
                                            onChange={(e) =>
                                              handleCheck(index, e, "no_of_chairs")
                                            }
                                          />
                                        </div>
                                      </div>

                                    </React.Fragment>
                                  ))}

                                  <ErrorMessage error={error} />

                                  <div className="modal-footer flex-center">
                                    <button
                                      type="submit"
                                      id="kt_modal_new_address_submit"
                                      className="btn btn-primary"
                                    >
                                      <span className="indicator-label">Save</span>
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-danger m-2"
                                      onClick={() => history.push(`/group_admin/branch_management/table_management/${id.id}`)}
                                    >
                                      {" "}
                                      Cancel{" "}
                                    </button>
                                  </div>
                                </div>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <LoadingBackdrop open={loading} />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  arealist: state?.areaList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getAreaList: (BranchId) => dispatch(getAreaList(BranchId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdminAddTable);
