import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import "./TableReservationModel.css"
import useError from '../../../Comman/Error/useError';
import api from '../../../../CommonApi/axios';
import headers from '../../../../CommonApi/headers';
import { showSuccessTableBook } from '../../../Comman/Swal/Swal';
import { ErrorMessage } from '../../../Comman/Error/ErrorMessage';
import { STAR } from './AddTableReservation/common';




export function TableEditMessger({ showEditMessage, handleEditMessage, getTableReservationList, DataTable }) {


      const { error, showError, clearError } = useError();
      const [message, setmessage] = useState("");

      // console.log("message" ,message)


      function handleTableAction(event) {
            event.preventDefault();
            clearError(null);


            if (message === "") {
                  showError("Please enter message.");
                  return;
            }

            else {

                  let item = {
                        message: message
                  }

                  api
                        .post(`/groupadmin/table_reservation/updateMessage/${DataTable?._id}`, item, { headers })
                        .then((response) => {
                              clearError(null);
                              showSuccessTableBook("Message has been edited successfully");
                              setTimeout(() => {
                                    getTableReservationList();
                              }, 1500);
                              handleEditMessage();
                        })
                        .catch((err) => {
                              // handleMenuClose();
                              showError(err?.response?.data?.error?.message);
                        });
            }
      }

      useEffect(() => {
            if (DataTable) {
                  setmessage(DataTable?.message || "")
            }
      }, [DataTable])


      return (
            <React.Fragment>
                  <Modal
                        show={showEditMessage} onHide={() => {
                              handleEditMessage()
                        }}>
                        <Modal.Body>
                              <Row>
                                    <Col className='mt-2 Redeem_' lg={12} md={12} xs={12} sm={12}>
                                     Message
                                    </Col>
                              </Row>
                              <Form >
                                    <Form.Group className="mb-3 mt-2" >


                                          <Form.Group className="mb-3" >
                                                <Form.Label className='Discount_label'>Message {STAR}</Form.Label>
                                                <Form.Control
                                                 as="textarea"
                                                 rows={5} // Adjust the number of rows as needed
                                                      required
                                                      type="text" placeholder="Enter Message"
                                                      value={message}
                                                      onChange={(e) => setmessage(e.target.value)}
                                                />
                                          </Form.Group>
                                    </Form.Group>
                                    <ErrorMessage error={error} />
                                    <Row>
                                          <Col className='my-2 mt-4' lg={6} md={6} xs={6} sm={6}>
                                                <Button type="button" className='Cancle_button' variant="outlined"
                                                      onClick={() => {
                                                            handleEditMessage();
                                                            clearError(null);
                                                      }}
                                                >
                                                      Cancel
                                                </Button >
                                          </Col>
                                          <Col className='my-2 mt-4' lg={6} md={6} xs={6} sm={6}>
                                                <Button
                                                      onClick={handleTableAction}
                                                      type="submit" className='Aplly_button' variant="contained" >
                                                      Confirm
                                                </Button>
                                          </Col>
                                    </Row>
                              </Form>
                        </Modal.Body>
                  </Modal>
            </React.Fragment>
      )
}

