import React from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import headers from "../../../../CommonApi/headers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import useDrawer from "../../../../context/DrawerContext";

export default function GroupAdminCompletedEnquiryDetails() {
  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url = pathnamesplits[2];
  const id = useParams();

  let history = useHistory()
  const [user, setUser] = useState("");

  useEffect(() => {
    CompletedEnquriyDetilsGet();
  }, []);

  function CompletedEnquriyDetilsGet() {
    api
      .get(`/groupAdmin/inquiry/${id.id}`, { headers })
      .then((res) => {
        const tableData = res.data.data?.inquiryData;
        setUser(tableData);
        // console.log(tableData)
      })
      .catch((err) => {
        console.log(err.response?.data?.error?.message);
      });
  }

  const { drawerPos, handleDrawer , maingetViewDetails } = useDrawer();


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer}/>
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                  <div className="row g-5 g-xl-8">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-lg-12 col-md-12">
                      <div className="card card-xl-stretch mb-5 mb-xl-8">
                      <div className="card card-xl-stretch mb-5 mb-xl-8">
                        <div className="card-header pt-5 border-1">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                       <h1 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder fs-3 mb-1">
                            Completed Enquiry Details
                            </span>
                          </h1>
                      </div>
                      <div className="d-flex flex-stack">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <button
                            onClick={() => history.goBack()}
                            className="btn btn-primary"
                            data-kt-menu-trigger="click"
                            data-kt-menu-attach="parent"
                            data-kt-menu-placement="bottom-end"
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                        </div>
                        </div>
                        <br />
                        <div className="card-body pt-1">
                          <div className="row p-3 pt-0">
                            <table
                              className="table table-bordered gy-2 gs-4 border rounded"
                              style={{ width: "100%" }}
                            >
                              <tbody>
                                <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    Name
                                  </th>
                                  <td>{user?.name}</td>
                                </tr>
                                <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    Phone Number
                                  </th>
                                  <td>{user?.phone_number}</td>
                                </tr>
                                <tr style={{ border: "1px solid #000" }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    Email
                                  </th>
                                  <td>{user?.email}</td>
                                </tr>
                                <tr style={{ border: "1px solid #000 " }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    Branch Name
                                  </th>
                                  <td>{user?.branch?.branch_name_EN}</td>
                                </tr>
                                <tr style={{ border: "1px solid #000 " }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    Enquiry Date
                                  </th>
                                  <td>{moment(user?.created_at).format("DD/MM/YYYY")}</td>
                                </tr>
                                <tr style={{ border: "1px solid #000 " }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    Subject
                                  </th>
                                  <td>{user?.subject}</td>
                                </tr>
                                <tr style={{ border: "1px solid #000 " }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    Message
                                  </th>
                                  <td>{user?.message}</td>
                                </tr>
                                <tr style={{ border: "1px solid #000 " }}>
                                  <th
                                    className="fw-bolder fs-6 text-gray-800 px-7"
                                    style={{
                                      backgroundColor: "darkgray",
                                      width: "30%",
                                    }}
                                  >
                                    Response
                                  </th>
                                  <td>{user?.response}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}
