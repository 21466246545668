import React from 'react';
import Chip from '@mui/material/Chip';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export const renderStatusChipSubcription = (status) => {
  let color = '';
  let label = '';
  let background = '';


  switch (status) {
    case "active":
      color = '#027A48';
      label = 'Active';
      background = '#ECFDF3';
      break;
    case "trial":
      background = '#EFF8FF';
      label = 'Trial';
      color = "#175CD3";
      break;
    case "pending":
      background = '#FFFAEB';
      label = 'Pending';
      color = "#B54708";
      break;
    case "expired":
      background = '#FEF3F2';
      label = 'Expired';
      color = "#B42318";
      break;
    default:
      color = '#6B7280'; // Default color (gray)
      label = 'Unknown';
      background = '#F3F4F6'; // Default background (light gray)
      break;
  }

  return (
    <Chip
      icon={<FiberManualRecordIcon style={{ color: color, fontSize: 13 }} />}
      label={label}
      style={{ color: color, background: background, fontSize: 13 }}
    />
  );
};

export const renderStatusChipPrinteList = (status, key) => {
  let color = '';
  let label = '';
  let background = '';

  switch (key) {
    case 'default_status':
    case 'enable_invoice':
      color = status ? '#027A48' : '#B54708';
      label = status ? 'Yes' : 'No';
      background = status ? '#ECFDF3' : '#FFFAEB';
      break;
    case 'is_active':
      color = status ? '#027A48' : '#B54708';
      label = status ? 'Active' : 'Inactive';
      background = status ? '#ECFDF3' : '#FFFAEB';
      break;
    default:
      color = '#000'; // Default color
      label = 'Unknown'; // Default label
      background = '#FFF'; // Default background
      break;
  }

  return (
    <Chip
      icon={<FiberManualRecordIcon style={{ color: color, fontSize: 13 }} />}
      label={label}
      style={{ color: color, background: background, fontSize: 13 }}
    />
  );
};

export const renderStatusChipTableReservations = (status) => {
  let color = '';
  let label = '';
  let background = '';


  switch (status) {
    case "accepted":
      color = '#175CD3';
      label = 'Accepted';
      background = '#EFF8FF';
      break;
    case "Guests Arrived":
      color = '#5925DC';
      label = 'Guests Arrived';
      background = '#F4F3FF';
      break;
    case "pending":
      background = '#FFFAEB';
      label = 'Pending';
      color = "#B54708";
      break;
    case "cancelled":
      background = '#FEF3F2';
      label = 'Cancelled';
      color = "#B42318";
      break;
    default:
      color = '#6B7280'; // Default color (gray)
      label = 'Unknown';
      background = '#F3F4F6'; // Default background (light gray)
      break;
  }

  return (
    <Chip
      icon={<FiberManualRecordIcon style={{ color: color, fontSize: 13 }} />}
      label={label}
      style={{ color: color, background: background, fontSize: 13 }}
    />
  );
};
