import React, { useEffect, useMemo, useState } from 'react'
import GroupAdminHeader from '../../../Comman/Header';
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import { Link, useHistory, useParams } from "react-router-dom";
// import QRCode from "qrcode.react";
import $ from "jquery";
import { useStyles } from '../../../Comman/TableuseStyles';
import { QRCode } from "react-qrcode-logo";
import CallMadeIcon from '@mui/icons-material/CallMade';
import DownloadIcon from '@mui/icons-material/Download';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { getAreaList, getBranchDetaisById } from '../../../../Store';
import { connect } from "react-redux";
import BranchCommon from '../../Page/BranchCommon';
import useDrawer from '../../../../context/DrawerContext';
import { deleteCommonFunction } from '../../../Comman/DeleteCommonFun/DeleteCommonFunction';
import { BranchesPermissions, userPermissionType } from '../../../Comman/UserPermission';
import DeleteIcon from '@mui/icons-material/Delete';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import ColumPopoverCommon from '../../../NewTableCommon/ColumPopoverCommon';
import TableFilter from '../../../NewTableCommon/tableFilter';
import useWindowHeight from '../../../NewTableCommon/useWindowsPosition';
import { tableOptionBranchList } from '../../../NewTableCommon/tableOption';
import { Open24Filled, QrCode24Filled } from '@fluentui/react-icons';
import FilterPopover from '../OrderManagement/FilterPopover';
import TableAreaFilter from './TableAreaFilter';
import ModelForTable from './ModelForTable/ModelForTable';
import ModelTableDataEdit from './ModelForTable/ModelTableDataEdit';
import api from '../../../../CommonApi/axios';
import headers from '../../../../CommonApi/headers';


function TableManagementInBranchView({ getBranchDetaisById, branchDetaislByID, getAreaList, arealist }) {



  const history = useHistory();
  const { id } = useParams();

  const classes = useStyles();
  const [TableNumber, setTableNumber] = useState("");
  const [presentNumber, setpresentNumber] = useState("");
  const [user, setuser] = useState("")
  const [Table, setTable] = useState([]);
  const [editdata, seteditdata] = useState("");

  useEffect(() => {
    if (id) {
      getBranchDetaisById(id);
      getAreaList(id);
    }
  }, [id]);


  // useEffect(() => {
  //   if (id) {
  //     api.get(`/groupadmin/table/list/${id}`, { headers }).then((res) => {
  //       const tableData = res?.data?.data;
  //       console.log("id" ,tableData);
  //     });
  //   }
  // }, [id]);

  useEffect(() => {
    if (branchDetaislByID) {
      setuser(branchDetaislByID);
      setTable(branchDetaislByID?.tableData?.length > 0 ? branchDetaislByID?.tableData : []);
      setpresentNumber(branchDetaislByID?.tableData?.length > 0 ? branchDetaislByID?.tableData?.length : 0)
    }
  }, [branchDetaislByID])


  const downloadCode = (Table) => {
    const canvas = document.getElementById(Table);
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `Table no ${Table}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };


  function phils_image_price(input) {
    const canvas = document.getElementById('roundedCanvas');
    const ctx = canvas.getContext('2d');

    // Draw a double rounded shape with a black border
    function drawDoubleRoundedShape() {
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;
      const outerRadius = canvas.width / 3;
      const innerRadius = outerRadius - 10; // You can adjust the difference based on the desired thickness of the border

      ctx.beginPath();

      // Draw the outer arc
      ctx.arc(centerX, centerY, outerRadius, 0, 2 * Math.PI);
      ctx.fillStyle = '#fff'; // Change the color if desired
      ctx.fill();

      // Draw the inner arc
      ctx.arc(centerX, centerY, innerRadius, 0, 2 * Math.PI);
      ctx.fillStyle = '#fff'; // Change the color if desired
      ctx.fill();

      ctx.lineWidth = 4; // You can adjust the line width based on the desired thickness of the border
      ctx.strokeStyle = 'black';
      ctx.stroke();

      ctx.closePath();
    }

    // Call the function to draw the double rounded shape
    drawDoubleRoundedShape();

    // You can also add the drawCenteredText function if needed
    function drawCenteredText() {
      const text = input;
      ctx.font = '35px Arial';
      ctx.fillStyle = '#000'; // Change the color if desired
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(text, canvas.width / 2, canvas.height / 2);
    }

    // Call the function to draw the centered text if needed
    drawCenteredText();

    // Convert the canvas to a data URL if needed
    return canvas.toDataURL();
  }


  const [show, setShow] = useState(false)

  const modelShow = () => {
    setShow(true);
  };

  const handleCloseModel = () => {
    setShow(false)
  }


  const downloadQRCodeAll = async () => {
    const qrContainers = document.querySelectorAll('.qr-code-container');
    const zip = new JSZip();

    for (let index = 0; index < qrContainers.length; index++) {
      const container = qrContainers[index];
      const canvas = container.querySelector('canvas');
      const tableNumber = container.getAttribute('data-table-number'); // Retrieve table_number from data attribute

      if (canvas && tableNumber) {
        const imageBlob = await convertCanvasToBlob(canvas);
        zip.file(`Table_no_${tableNumber}.png`, imageBlob); // Include table_number in the file name
      }
    }

    zip.generateAsync({ type: 'blob' }).then(content => {
      saveAs(content, 'QR_Codes.zip');
    });
  };

  async function convertCanvasToBlob(canvas) {
    return new Promise(resolve => {
      canvas.toBlob(blob => {
        resolve(blob);
      }, 'image/png');
    });
  }
  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

  const userPermissionType_ = userPermissionType === "groupadmin"

  const windowHeight = useWindowHeight();
  const pathname = window.location.pathname;
  const pathnamesplits = pathname.split("/");
  const url = pathnamesplits[3];

  const columnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
  const DisplayCol = columnsData && Object.values(columnsData).includes(false);
  const filterData = JSON.parse(sessionStorage.getItem(`${url}_filterData`));

  // console.log("filterData", filterData)

  const [updateColumns, setUpdateColumns] = useState(false);
  const ColumnsDataString = sessionStorage.getItem(`${url}_columnData`);
  const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;


  const [FilterData, SetFilterData] = useState("");
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
    setIsFilterPopoverOpen(true);
  };

  const handleFilterClose = () => {
    setIsFilterPopoverOpen(false);
  };

  const handleApplyFilters = (filterData) => {
    setIsFilterPopoverOpen(false);
    SetFilterData(filterData)
  };

  const [anchorEl_, setAnchorEl_] = useState(null);

  const handleClickColum_ = (event) => {
    setAnchorEl_(event.currentTarget);
  };
  const handleCloseColum_ = () => {
    setAnchorEl_(null);
  };

  const onColumnDataChange = () => {
    setUpdateColumns(prevState => !prevState);
  };

  // console.log("arealistBranchList_", BranchList_)

  const EDIT = BranchesPermissions?.access?.includes("edit");
  const DELETE = BranchesPermissions?.access?.includes("delete");
  const VIEW = BranchesPermissions?.access?.includes("view");


  const filteredTableList = useMemo(() => {
    const floorArea = FilterData?.areafloor?.value;
    const orderType = FilterData?.order_type?.value;

    return Table.filter(menu =>
      (!floorArea || menu.area_id === floorArea) &&
      (!orderType || menu.order_type === orderType)
    );
  }, [Table, FilterData?.areafloor?.value, FilterData?.order_type?.value]);


  const ColumnsManager = useMemo(() => {
    const columns = [
      {
        accessorKey: '_id',
        header: 'Sr.No',
        size: 100,
        Cell: ({ row }) => Number(row.index + 1),
      },
      {
        accessorKey: 'table_number',
        header: 'Table No',
        size: 100,
        Cell: ({ row }) => (row?.original?.table_number),
      },
      {
        accessorKey: 'area_name',
        header: 'Area',
        size: 200,
        Cell: ({ row }) => (row?.original?.area_name || "---"),
      },

      {
        accessorKey: 'no_of_chairs',
        header: 'No. of Chairs',
        size: 100,
        Cell: ({ row }) => Number(row?.original?.no_of_chairs),
      },

      {
        accessorKey: 'order_type',
        header: 'Order Type',
        size: 250,
        Cell: ({ row }) => <span style={{ textTransform: "capitalize" }}>{(row?.original?.order_type || "---")}</span>,
      },
      {
        accessorKey: 'phone_number',
        header: 'QR Link',
        // size: 270,
        Cell: ({ row }) => (<div className="text-start">
          <button className="settingss btn btn-danger btn-sm" style={{ fontSize: "inherit" }}
            disabled={row?.original?.order_type !== "dineIn"}
            onClick={() => window.open(`http://${row?.original?.qr_code}`, '_blank', 'noopener,noreferrer')}
          >
            {" "}
            <Open24Filled className='me-2' /> QR Link
          </button>
        </div>)
      },
      {
        accessorKey: 'qr_code',
        header: 'QR Code',
        size: 250,
        Cell: ({ row }) => <div>
          <div style={{ display: "none" }}>
            <QRCode
              id={row?.original?.table_number}
              value={row?.original?.qr_code}
              size={260}
              logoImage={phils_image_price(row?.original?.table_number)}
              logoHeight={100}
              logoWidth={100}
              logoOpacity={1}
              enableCORS={true}
              qrStyle="dots"
              eyeRadius={20}
            >

            </QRCode>
          </div>
          <div className="text-start">
            <button disabled={row?.original?.order_type !== "dineIn"} className="settingss btn btn-success" style={{ fontSize: "inherit" }} onClick={() => downloadCode(row?.original?.table_number)}>
              {" "}
              <QrCode24Filled className='me-2' /> Download QR{" "}
            </button>
          </div>
        </div>,
      },
    ];
    return dineInColumnsData === null ? columns : columns?.filter(column => dineInColumnsData[column?.header] === true);
  }, [updateColumns]);
  // console.log("url" ,url)

  const handleEditTableData = (data) => {
    seteditdata(data);
    modelEditShow()
  }

  const table = useMaterialReactTable({
    columns: ColumnsManager,
    data: filteredTableList,
    ...tableOptionBranchList({
      getBranchDetaisById,
      url, id,
      handleClickColum_,
      // handleSubmitActive,
      handleFilterClick,
      windowHeight,
      filterData,
      DisplayCol,
      DELETE,
      // VIEW,
      EDIT,
      history,
      handleEditTableData
    }),
  });

  const [showEdit, setShowEdit] = useState(false)

  const modelEditShow = () => {
    setShowEdit(true);
    // setErrorForgate(null)
  };

  const handleEditCloseModel = () => {
    setShowEdit(false)
    seteditdata("")
  }


  return (

    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className="d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main style={{ position: "initial", marginTop: "-27px" }} className={maingetViewDetails.join(" ")}>

                    <div className="d-flex flex-stack mb-5">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h1
                          className="anchor fw-bolder mb-5"
                          id="custom-form-control"
                        >
                          Branches
                          {/* {user?.resData?.branch_name_EN && ` - ${user?.resData?.branch_name_EN}`} */}
                        </h1>
                      </div>
                      <div className="d-flex flex-stack mb-5">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          {Table.length > 0 &&
                            Table?.filter((main) => main?.order_type === "dinein") &&
                            <button
                              className="settingss btn btn-primary me-3"
                              onClick={downloadQRCodeAll}
                              color="primary"
                              variant="contained"
                              style={{ textTransform: 'none' }}
                              size="large"
                            // disabled={Table.length > 0 ? false : true}
                            >

                              <DownloadIcon />  Download QR-Codes
                            </button>
                          }

                          {(userPermissionType === "groupadmin" || BranchesPermissions?.access?.includes("create")) && (
                            <button
                              type='button'
                              onClick={modelShow}
                              className="btn btn-primary"
                            >
                              Create table/s
                            </button>
                          )}

                        </div>
                      </div>
                    </div>

                    <hr style={{ opacity: 0.07, marginTop: "-13px" }} />
                    <div className="d-flex flex-stack mb-3">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        {user?.resData?.branch_name_EN ?
                          <h3
                            className="anchor fw-bolder mb-3"
                            id="custom-form-control"
                          >
                            Branch - {user?.resData?.branch_name_EN}
                          </h3> : ""}
                      </div>
                    </div>
                    <div className={"rounded border p-5"}>
                      <BranchCommon />

                      <div className="mt-5">
                        <MaterialReactTable
                          table={table}
                        />
                      </div>

                      {Table?.length > 0 &&
                        Table?.filter((main) => main?.order_type === "dinein")?.map((rowData, index) => (
                          <div key={index} data-table-number={rowData?.table_number}
                            className="qr-code-container" style={{ display: "none" }}>
                            <QRCode
                              id={rowData?.table_number}
                              value={rowData?.qr_code}
                              size={260}
                              logoImage={phils_image_price(rowData?.table_number)}
                              logoHeight={100}
                              logoWidth={100}
                              logoOpacity={1}
                              enableCORS={true}
                              qrStyle="dots"
                              eyeRadius={20}
                            >
                            </QRCode>
                          </div>
                        ))}
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {console.log("Table", Table)} */}

        <ColumPopoverCommon
          anchorEl_={anchorEl_}
          handleClickColum_={handleClickColum_}
          onColumnDataChange={onColumnDataChange}
          handleCloseColum_={handleCloseColum_}
          url23={url}
        />

        {/* <TableFilter
          open={isFilterPopoverOpen}
          anchorEl={filterAnchorEl}
          handleClose={handleFilterClose}
          handleFilter={handleApplyFilters}
          url23={url}
        /> */}


        <TableAreaFilter
          url={url}
          open={isFilterPopoverOpen}
          anchorEl={filterAnchorEl}
          Type={""}
          handleClose={handleFilterClose}
          handleFilter={handleApplyFilters}
          arealist={arealist}
        />



        <GroupAdminUperSide />
        <canvas
          id="roundedCanvas"
          width={200}
          height={200}
          style={{
            border: "1px solid black",
            borderRadius: "50%",
            display: "none"
          }}
        />
      </div>


      <ModelForTable
        show={show}
        handleCloseModel={handleCloseModel}
        url={url}
        presentNumber={presentNumber}
      />


      <ModelTableDataEdit
        showEdit={showEdit}
        handleEditCloseModel={handleEditCloseModel}
        url={url}
        getBranchDetaisById={() => getBranchDetaisById(id)}
        editdata={editdata}
        arealist={arealist}
      />


    </React.Fragment>
  )
}



const mapStateToProps = (state) => ({
  branchDetaislByID: state?.branch?.branchDetaislByID,
  arealist: state?.areaList,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchDetaisById: (id) => dispatch(getBranchDetaisById(id)),
    getAreaList: (id) => dispatch(getAreaList(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableManagementInBranchView);
