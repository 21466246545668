import moment from "moment";

export const generateTimeSlots = (startTime, endTime, interval, formattedTargetDate) => {
    const slots = [];
    const currentMoment = moment();
    const isToday = currentMoment.isSame(moment(formattedTargetDate, "YYYY-MM-DD"), 'day');

    let currentTime = moment(startTime, "HH:mm");
    const endTimeMoment = moment(endTime, "HH:mm");

    while (currentTime <= endTimeMoment) {
        if (!isToday || currentTime.isAfter(currentMoment)) {
            slots.push(currentTime.format("hh:mm A")); // 12-hour format
        }
        currentTime = currentTime.add(interval, "minutes");
    }

    return slots;
};

export const filterAndGenerateSlots = (BranchData, value) => {
    const formattedTargetDate = moment(value).format("YYYY-MM-DD");
    const dayOfWeek = moment(formattedTargetDate).format("dddd").toLowerCase();

    if (!BranchData) return { lunchSlots: [], dinnerSlots: [], guestsMin_Max: [] };

    if (BranchData?.slot_management_type === "manual") {
        const manualDates = BranchData?.manual_dates || [];
        const matchingSlots = manualDates.filter(slot => slot.date === formattedTargetDate);

        if (matchingSlots.length === 0) return { lunchSlots: [], dinnerSlots: [], guestsMin_Max: [] };

        const lunchSlots = matchingSlots
            .filter(slot => slot.booking_type === "Lunch")
            .sort((a, b) => moment(a.start_time, "HH:mm").diff(moment(b.start_time, "HH:mm")))
            .flatMap(slot => generateTimeSlots(slot.start_time, slot.end_time, slot.booking_interval, formattedTargetDate));

        const dinnerSlots = matchingSlots
            .filter(slot => slot.booking_type === "Dinner")
            .sort((a, b) => moment(a.start_time, "HH:mm").diff(moment(b.start_time, "HH:mm")))
            .flatMap(slot => generateTimeSlots(slot.start_time, slot.end_time, slot.booking_interval, formattedTargetDate));

        return { lunchSlots, dinnerSlots, guestsMin_Max: [] };

    } else if (BranchData?.slot_management_type === "auto") {
        const daySlots = BranchData?.[dayOfWeek] || [];
        if (daySlots.length === 0) return { lunchSlots: [], dinnerSlots: [], guestsMin_Max: [] };

        const startTimeKey = `${dayOfWeek.slice(0, 3)}_start_time`;
        const endTimeKey = `${dayOfWeek.slice(0, 3)}_end_time`;

        const lunchSlots = daySlots
            .filter(slot => slot.booking_type === "Lunch")
            .sort((a, b) => moment(a[startTimeKey], "HH:mm").diff(moment(b[startTimeKey], "HH:mm")))
            .flatMap(slot => generateTimeSlots(slot[startTimeKey], slot[endTimeKey], slot.booking_interval, formattedTargetDate));

        const dinnerSlots = daySlots
            .filter(slot => slot.booking_type === "Dinner")
            .sort((a, b) => moment(a[startTimeKey], "HH:mm").diff(moment(b[startTimeKey], "HH:mm")))
            .flatMap(slot => generateTimeSlots(slot[startTimeKey], slot[endTimeKey], slot.booking_interval, formattedTargetDate));

        return { lunchSlots, dinnerSlots, guestsMin_Max: [] };
    }

    return { lunchSlots: [], dinnerSlots: [], guestsMin_Max: [] };
};
