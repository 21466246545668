import { Popover, List, ListItem, Checkbox, FormControlLabel, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import "./ColumPopover.css";


export default function ColumPopoverTableReservation({ anchorEl, url23, handleCloseColum, onColumnDataChange, Type, BranchId, currencyLabel }) {
      const [columnData, setColumnData] = useState({});
      const [Array_, setArray] = useState("")

      // console.log("BranchId" ,BranchId , currencyLabel);
      console.log("Type", Type)

      const columnsTableReservations = [
            {
                  header: 'Reservation ID',
            },
            {
                  header: 'Branch',
            },
            {
                  header: 'Reservation Type',
            },
            {
                  header: 'Booking Date & Time',
            },
            {
                  header: 'Reservation Date & Time',
            },
            {
                  header: "Request Type"
            },
            {
                  header: "Table No"
            },
            {
                  header: 'Total Guests',
            },
            {
                  header: 'Customer Details',
            },
            {
                  header: 'Status',
            },
            {
                  header: 'Special Requirements',
            },
            {
                  header: 'Message',
            },


      ];

      const storedData = sessionStorage.getItem(Type === "table" ? `${url23}_columnData` : Type === "allData" ? `${url23}_${Type}_columnData` : {});
      // console.log("storedData" ,storedData)
      
      useEffect(() => {
            // if (storedData === null) {
                  const initialData = Object.fromEntries(columnsTableReservations.map(column => [column.header, true]));
                  // const initialData1 = Object.fromEntries(columnsPickupAndDelivery.map(column => [column.header, true]));
                  if (Type === "table") {
                        sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(initialData));
                        setColumnData(initialData);
                  } else if (Type === "allData") {
                        sessionStorage.setItem(`${url23}_${Type}_columnData`, JSON.stringify(initialData));
                        setColumnData(initialData);
                        // console.log("pickup")
                  }
                  setArray(columnsTableReservations)
                  onColumnDataChange()
            // }
      }, [Type, BranchId]);

      const handleCheckboxClick = (columnName, isChecked) => {
            const updatedColumnData = {
                  ...columnData,
                  [columnName]: isChecked
            };
            if (Type === "table") {
                  sessionStorage.setItem(`${url23}_columnData`, JSON.stringify(updatedColumnData));
            } else if (Type === "allData") {
                  sessionStorage.setItem(`${url23}_${Type}_columnData`, JSON.stringify(updatedColumnData));
            }
            setColumnData(updatedColumnData);
            onColumnDataChange(updatedColumnData);
      };

      const isColumnChecked = (columnName) => {
            return columnData[columnName] === true;
      };



      return (
            <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleCloseColum}
                  anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                  }}
                  transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                  }}
                  PaperProps={{
                        elevation: 0,
                        sx: {
                              overflow: 'visible',
                              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                              borderRadius: 3,
                              mt: 1.5,
                              '& .MuiAvatar-root': {
                                    width: 35,
                                    height: 35,
                                    ml: -0.5,
                                    mr: 1,
                              },
                              '&::before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 85,
                                    width: 13,
                                    height: 13,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                              },
                        },
                  }}
            >
                  <Typography variant="h6" sx={{ px: 2, py: 1 }}>
                        Display Columns
                  </Typography>
                  <List sx={{ px: 1 }}>
                        {Array_ !== "" && Array_?.map((column, index) => (
                              <ListItem sx={{ py: 0 }} key={index}
                                    style={{
                                          display: column?.header === "Reservation ID"
                                                ? "none" : ""
                                    }}>
                                    <FormControlLabel
                                          control={
                                                <Checkbox
                                                      className="checkbox"
                                                      checked={isColumnChecked(column.header)}
                                                      onClick={(e) => handleCheckboxClick(column.header, e.target.checked)} />
                                          }
                                          label={
                                                <Typography
                                                      className="checkboxname">{column.header}</Typography>
                                          }
                                    />
                              </ListItem>
                        ))}
                  </List>
            </Popover>
      );
}
