import React, { useEffect, useState } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import { Link } from "react-router-dom";
import { UserPermissionAllData, userPermissionType } from "../../../../Comman/UserPermission";
import useDrawer from "../../../../../context/DrawerContext";

export default function GroupAdminReportListpage() {
  const ReportsPermissions = UserPermissionAllData?.role_details?.reports;

  const [filteredMenuItems, setFilteredMenuItems] = useState([]);

  const userPermissionType_ = userPermissionType === "groupadmin"


  const reportData = [
    {
      link: "/group_admin/report/revenue_based_report",
      title: "Revenue Reports",
      key: "revenue_reports",
      icon: `/assets/media/Reports Icons/Revenue.png`,
    },
    {
      link: "/group_admin/report/order_reports",
      title: "Order Reports",
      key: "order_reports",
      icon: `/assets/media/Reports Icons/Order.png`,
    },
    {
      link: "/group_admin/report/product_wise_reports",
      title: "Product Reports",
      key: "product_reports",
      icon: `/assets/media/Reports Icons/Product.png`,
    },
    {
      link: "/group_admin/report/group_report",
      title: "General Reports",
      key: "general_reports",
      icon: `/assets/media/Reports Icons/General.png`,
    },
    {
      link: "/group_admin/report/bills",
      title: "Billing Reports",
      key: "billing_reports",
      icon: `/assets/media/Reports Icons/Billing.png`,
    },
    {
      link: "/group_admin/report/voucher",
      title: "Voucher Reports",
      key: "voucher_reports",
      icon: `/assets/media/Reports Icons/Voucher.png`,
    },
    {
      link: "/group_admin/report/tour_gorup_report",
      title: "Tour Group Reports",
      key: "tour_group_reports",
      icon: `/assets/media/Reports Icons/Tour.png`,
    },
    // {
    //   link: "/group_admin/report/table_reservation_report",
    //   title: "Table Reservation Reports",
    //   key: "table_reservation_report",
    //   icon: `/assets/media/Reports Icons/TableReservation.png`,
    // },
  ];

  useEffect(() => {

    if(userPermissionType_){
      setFilteredMenuItems(reportData);
    }else{
    // Filter reportData based on the user's permissions
    let newFilteredMenuItems = reportData?.filter(report => {
      const matchingPermission = ReportsPermissions?.find(permission => 
        permission.key === report.key && permission.access.length > 0
      );
      return matchingPermission !== undefined;
    });

    // If the user has a specific role type, further filter the results
    if (userPermissionType === "TGA") {
      newFilteredMenuItems = newFilteredMenuItems.filter(item =>
        item.link.includes("tour")
      );
    }

    setFilteredMenuItems(newFilteredMenuItems);
  }
  }, [ReportsPermissions, userPermissionType]);

  const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main style={{ position: "initial" }} className={mainClassCustome.join(" ")}>
                    <div className="row g-5 g-xl-8">
                      <h1 className="my-3">Reports</h1>
                      {filteredMenuItems.map((report, index) => (
                        <div key={index} className="col-sm-4">
                          <Link
                            to={report.link}
                            className="card bg-white hoverable mb-xl-8"
                            style={{
                              boxShadow: "0 0 20px 0 rgb(123 123 123 / 46%)",
                            }}
                          >
                            <div className="card-body p-2">
                              <center>
                                <div className="my-3">
                                  <img src={report.icon} alt={report.title} />
                                </div>
                                <div className="mb-2">
                                  <h2 className="fw-normal">{report.title}</h2>
                                </div>
                              </center>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}
