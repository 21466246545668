import { AREALIST } from "../action-types";

const getAreaList = (id) => ({
      type: AREALIST.GET_AREALIST,
      id
});   


const setAreaList = (arealist) => ({
      type: AREALIST.SET_AREALIST,
      arealist,
});


export {
      getAreaList, setAreaList
};