import React, { useEffect, useMemo, useState } from 'react'
import GroupAdminHeader from '../../../Comman/Header';
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import { Link, useHistory, useParams } from "react-router-dom";
// import QRCode from "qrcode.react";
import $ from "jquery";
import { useStyles } from '../../../Comman/TableuseStyles';
import { QRCode } from "react-qrcode-logo";
import CallMadeIcon from '@mui/icons-material/CallMade';
import DownloadIcon from '@mui/icons-material/Download';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { getAreaList } from '../../../../Store';
import { connect } from "react-redux";
import BranchCommon from '../../Page/BranchCommon';
import useDrawer from '../../../../context/DrawerContext';
import { deleteCommonFunction } from '../../../Comman/DeleteCommonFun/DeleteCommonFunction';
import { BranchesPermissions, userPermissionType } from '../../../Comman/UserPermission';
import DeleteIcon from '@mui/icons-material/Delete';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import ColumPopoverCommon from '../../../NewTableCommon/ColumPopoverCommon';
import TableFilter from '../../../NewTableCommon/tableFilter';
import useWindowHeight from '../../../NewTableCommon/useWindowsPosition';
import { tableOptionBranchList } from '../../../NewTableCommon/tableOption';
import { HeaderStyle } from '../OrderManagement/TableCommonModule';
import ModelForTable from './ModelForTable/ModelForTable';



function TableManAreaList({ getAreaList, arealist, branchDetaislByID }) {

      const history = useHistory();
      const { id } = useParams();

      const [TableNumber, setTableNumber] = useState("");
      const [presentNumber, setpresentNumber] = useState("");
      const [user, setuser] = useState("")
      const [Table, setTable] = useState([]);
      const [editdata ,seteditdata] = useState("");
      // console.log("arealist", arealist)

      useEffect(() => {
            if (id) {
                  getAreaList(id);
            }
      }, [id]);

      useEffect(() => {
            if (branchDetaislByID) {
                  setuser(branchDetaislByID);
                  setTable(branchDetaislByID?.tableData?.length > 0 ? branchDetaislByID?.tableData : []);
                  setpresentNumber(branchDetaislByID?.tableData?.length > 0 ? branchDetaislByID?.tableData?.length : 0)
            }
      }, [arealist])



      const [errorForgate, setErrorForgate] = useState(null);
      const errorDivForgate = errorForgate ? (
            <div className="text-center alert alert-danger">{errorForgate}</div>
      ) : (
            ""
      );

      const handleClose = () => {
            setTableNumber("");
            setErrorForgate(null)
      };

      function handleSubmitForgetPassword(event) {
            event.preventDefault();
            setErrorForgate(null)

            // history.push("/reset_password/12344");
            if (TableNumber == 0) {
                  setErrorForgate("Table number value is accept greater than 0 ")
                  return
            }
            else {
                  history.push(`/group_admin/branch_management/add_table/${id}/${TableNumber}/${presentNumber}`);
                  window.location.reload(true)
            }

      }


      const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

      const userPermissionType_ = userPermissionType === "groupadmin"

      const windowHeight = useWindowHeight();
      const pathname = window.location.pathname;
      const pathnamesplits = pathname.split("/");
      const url = pathnamesplits[3];
      // console.log("url", url)

      const columnsDataString = sessionStorage.getItem(`${url}_columnData`);
      const columnsData = columnsDataString ? JSON.parse(columnsDataString) : null;
      const DisplayCol = columnsData && Object.values(columnsData).includes(false);
      const filterData = JSON.parse(sessionStorage.getItem(`${url}_filterData`));
      // const branchName = JSON.parse(sessionStorage.getItem(`${url}_branchName`));
      // const [BranchId, setBranchID] = useState(branchName || "");

      const [updateColumns, setUpdateColumns] = useState(false);
      const ColumnsDataString = sessionStorage.getItem(`${url}_columnData`);
      const dineInColumnsData = ColumnsDataString ? JSON.parse(ColumnsDataString) : null;

      const EDIT = BranchesPermissions?.access?.includes("edit");
      const DELETE = BranchesPermissions?.access?.includes("delete");
      const VIEW = BranchesPermissions?.access?.includes("view");

      const ColumnsManager = useMemo(() => {
            const columns = [
                  // {
                  //   accessorKey: '_id',
                  //   header: 'Sr.No',
                  //   size: 100,
                  //   Cell: ({ row }) => Number(row.index + 1),
                  // },

                  {
                        accessorKey: 'area_name',
                        header: 'Area Name',
                        size: 900,
                        Cell: ({ row }) => <span style={HeaderStyle?.uniqueCodeHeader}>
                              {row?.original?.area_name}
                        </span>,
                  },
            ];
            return dineInColumnsData === null ? columns : columns?.filter(column => dineInColumnsData[column?.header] === true);
      }, [updateColumns]);
      // console.log("url" ,url)


      const handleEditArea = (Data) => {
            seteditdata(Data);
            // setId(Data._id);
            modelShow();
          };

      const table = useMaterialReactTable({
            columns: ColumnsManager,
            data: arealist,
            ...tableOptionBranchList({
                  url, id,
                  windowHeight,
                  filterData,
                  DisplayCol,
                  DELETE,
                  // VIEW,
                  EDIT,
                  history,
                  handleEditArea,getAreaList
            }),
      });

      const [show, setShow] = useState(false)

      const modelShow = () => {
            setShow(true);
            // setErrorForgate(null)
      };

      const handleCloseModel = () => {
            setShow(false)
            seteditdata("")
      }


      return (

            <React.Fragment>
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div
                                          className="wrapper d-flex flex-column flex-row-fluid"
                                          id="kt_wrapper"
                                    >
                                          <GroupAdminHeader />
                                          <div
                                                className="d-flex flex-column flex-column-fluid"
                                                id="kt_content"
                                          >
                                                <div id="kt_content_container" className="mx-7">
                                                      <main style={{ position: "initial", marginTop: "-27px" }} className={maingetViewDetails.join(" ")}>

                                                            <div className="d-flex flex-stack mb-5">
                                                                  <div
                                                                        className="d-flex justify-content-end"
                                                                        data-kt-docs-table-toolbar="base"
                                                                  >
                                                                        <h1
                                                                              className="anchor fw-bolder mb-5"
                                                                              id="custom-form-control"
                                                                        >
                                                                              Branches
                                                                              {/* {user?.resData?.branch_name_EN && ` - ${user?.resData?.branch_name_EN}`} */}
                                                                        </h1>
                                                                  </div>
                                                                  <div className="d-flex flex-stack mb-5">
                                                                        <div
                                                                              className="d-flex justify-content-end mx-2"
                                                                              data-kt-docs-table-toolbar="base"
                                                                        >
                                                                              {(userPermissionType === "groupadmin" || BranchesPermissions?.access?.includes("create")) && (
                                                                                    <button
                                                                                          onClick={modelShow}
                                                                                          type='button'
                                                                                          className="btn btn-primary"
                                                                                    >
                                                                                          Create Area
                                                                                    </button>
                                                                              )}

                                                                        </div>
                                                                  </div>
                                                            </div>

                                                            <hr style={{ opacity: 0.07, marginTop: "-13px" }} />
                                                            <div className="d-flex flex-stack mb-3">
                                                                  <div
                                                                        className="d-flex justify-content-end"
                                                                        data-kt-docs-table-toolbar="base"
                                                                  >
                                                                        {user?.resData?.branch_name_EN ?
                                                                              <h3
                                                                                    className="anchor fw-bolder mb-3"
                                                                                    id="custom-form-control"
                                                                              >
                                                                                    Branch - {user?.resData?.branch_name_EN}
                                                                              </h3> : ""}
                                                                  </div>
                                                            </div>
                                                            <div className={"rounded border p-5"}>
                                                                  <BranchCommon />

                                                                  <div className="mt-5">
                                                                        <MaterialReactTable
                                                                              table={table}
                                                                        />
                                                                  </div>


                                                            </div>
                                                      </main>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>


                        <GroupAdminUperSide />
                  </div>

                  <ModelForTable
                        show={show}
                        handleCloseModel={handleCloseModel}
                        url={url}
                        getAreaList={()=>getAreaList(id)}
                        editdata={editdata}
                  />


            </React.Fragment>
      )
}



const mapStateToProps = (state) => ({
      arealist: state?.areaList,
});

const mapDispatchToProps = (dispatch) => {
      return {
            getAreaList: (id) => dispatch(getAreaList(id)),
      };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableManAreaList);
