import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GroupAdminHeader from "../../Comman/Header";
import GroupAdminSidebar from "../../Comman/Sidebar";
import GroupAdminUperSide from "../../Comman/UperSide";
import Select from "react-select";
import moment from 'moment';
import 'moment-timezone';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import $ from "jquery";
import useDrawer from "../../../context/DrawerContext";
import { SubmitformButton } from "../../Comman/Error/SubmitformButton";
import { ErrorMessage } from "../../Comman/Error/ErrorMessage";
import { passwordValidation, validateEmail } from "../../Comman/AddCommonFun/validation";
import { submitForm } from "../../Comman/AddCommonFun/AddCommonFunction";
import useError from "../../Comman/Error/useError";
import { applyFilterMeHandlers } from "../../Comman/Error/jqueryUtils";
import { currencyOptions, groupAdminSetup } from "../../Comman/CommonFunction/CommonFunction";
import GoBackButton from "../../Comman/GoBackButton/GoBackButton";
import Swal from "sweetalert2";
import headers from "../../../CommonApi/headers";
import api from "../../../CommonApi/axios";
import { showSuccessNotification } from "../../Comman/Swal/Swal";
import { Helmet } from "react-helmet";
window.jquery = window.$ = $;

export default function GroupAdminAddBranch() {


  

  useEffect(() => {
    applyFilterMeHandlers(); // Apply the jQuery handlers
  }, []);



  const [googleMapKey, setGoogleMapKey] = useState("");
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);


  useEffect(() => {
    groupAdminSetup(setGoogleMapKey, setIsScriptLoaded);
  }, []);

 
  // console.log("googleMapKey", googleMapKey)
  // console.log("isScriptLoaded", isScriptLoaded)



  let history = useHistory();

  const [pos_id, setpos_id] = useState("");
  const [pos_username, setpos_username] = useState("");
  const [pos_password, setpos_password] = useState("");
  const [address, setaddress] = useState("");
  const [email, setemail] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [manager_password, setmanager_password] = useState("");
  const [manager_email, setmanager_email] = useState("");
  const [manager_name, setmanager_name] = useState("");
  const [manager_phone_number, setmanager_phone_number] = useState("");
  const [branch_name_DE, setbranch_name_DE] = useState("");
  const [branch_name_EN, setbranch_name_EN] = useState("");
  const [branch_name_FR, setbranch_name_FR] = useState("");
  const [branch_name_IT, setbranch_name_IT] = useState("");
  const [branch_name_AR, setbranch_name_AR] = useState("");
  const [document, setdocument] = useState("");
  const [menu, setmenu] = useState("");
  const [lat, setlat] = useState("");
  const [lng, setlng] = useState("");
  const [FullAddress, setFullAddress] = useState([]);
  const [VAT_number, setVAT_number] = useState("");
  const [primary_language, setprimary_language] = useState("");
  const [languange, setlanguange] = useState([]);
  const withoutDuplicates = [...new Set(languange)];
  const [BranchTimeZone, setBranchTimeZone] = useState("");
  const [currency, setcurrency] = useState("");
  const [seq_no, setseq_no] = useState("");
  const [tripadvisor_url ,settripadvisor_url] = useState("");
  const [google_url ,setgoogle_url] = useState("");
  // console.log(withoutDuplicates);

  const CheckHandler = (event) => {
    var permissions_array = [...languange];
    if (event.target.checked) {
      permissions_array = [...languange, event.target.value];
    } else {
      permissions_array.splice(languange.indexOf(event.target.value), 1);
    }
    setlanguange(permissions_array);
  };

  const CheckHandlerRadio = (e) => {
    setprimary_language(e.target.value);
  };

  const { error, showError, clearError } = useError();

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);

    const ll = await getLatLng(result[0]);

    const sp = value.split(",");
    let area = "";
    for (let i = 1; i < sp.length; i++) {
      area += sp[i];
    }
    setaddress(value);
    setFullAddress(value);
    // setaddress_line2(area);
    // setcoordinates(ll);
    setlat(ll.lat ? ll.lat : "");
    setlng(ll.lng ? ll.lng : "");
  };

  const [imgData, setImgData] = useState(null);
  const [image, setimage] = useState("");

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setImgData(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setimage(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    clearError(null);

    if (lat === "" && lng === "") {
      showError("Please Select your branch location");
      return;
    }
    if (!validateEmail(email)) {
      showError("The entered branch email is invalid.");
      return;
    }
    if (!validateEmail(manager_email)) {
      showError("The entered manager email is invalid.");
      return;
    } else if (!passwordValidation(manager_password) || manager_password.toString().trim().split(" ").join("").length < 6) {
      showError("Password Must Be at least 6 character Long & doesn't contain any Space.");
      // $(".loader").hide();
    }
    if (BranchTimeZone === "") {
      showError("Please select branch time zone");
      return;
    }
    if (currency === "") {
      showError("Please select branch currency");
      return;
    }
    if (document === "") {
      showError("Please upload a file for the terms and conditions.");
      return;
    }
    else {
      $(".loader").show();
      const formData = new FormData();

      if (pos_id) {
        formData.append("pos_id", pos_id);
      }
      if (address) {
        formData.append("address", address);
      }
      if (email) {
        formData.append("email", email);
      }
      if (phone_number) {
        formData.append("phone_number", phone_number);
      }
      if (pos_password) {
        formData.append("pos_password", pos_password);
      }
      if (pos_username) {
        formData.append("pos_username", pos_username);
      }
      if (manager_name) {
        formData.append("manager_name", manager_name);
      }
      if (manager_email) {
        formData.append("manager_email", manager_email);
      }
      if (manager_phone_number) {
        formData.append("manager_phone_number", manager_phone_number);
      }
      if (manager_password) {
        formData.append("manager_password", manager_password);
      }
      if (document[0]) {
        formData.append("document", document[0]);
      }
      if (lat) {
        formData.append("lat", lat);
      }
      if (lng) {
        formData.append("long", lng);
      }
      if (branch_name_EN) {
        formData.append("branch_name_EN", branch_name_EN);
      }
      if (branch_name_DE) {
        formData.append("branch_name_DE", branch_name_DE);
      }
      if (branch_name_FR) {
        formData.append("branch_name_FR", branch_name_FR);
      }
      if (branch_name_IT) {
        formData.append("branch_name_IT", branch_name_IT);
      }
      if (branch_name_AR) {
        formData.append("branch_name_AR", branch_name_AR);
      }
      if (menu) {
        formData.append("menu", menu[0]);
      }
      if (VAT_number) {
        formData.append("VAT_number", VAT_number);
      }
      if (imgData) {
        formData.append("branch_icon", imgData);
      }
      if (primary_language) {
        formData.append("primary_language", primary_language);
      }
      if (BranchTimeZone) {
        formData.append("branchTimeZone", BranchTimeZone);
      }
      if (currency) {
        formData.append("currency", currency);
      }
      if(google_url){
        formData.append("google_url", google_url);
      }
      if(tripadvisor_url){
        formData.append("tripadvisor_url", tripadvisor_url);
      }

      if (languange.length > 0) {
        if (languange.length === 1) {
          if (languange[0] !== "") {
            formData.append("languange", JSON.stringify(withoutDuplicates));
          }
        } else {
          formData.append("languange", JSON.stringify(withoutDuplicates));
        }
      }
      formData.append("seq_no", seq_no);

      // console.log(Object.fromEntries(formData.entries()));
      // return

      // submitForm("/groupadmin/branch", formData, history, showError, clearError, "Branch has been added succesfully.");

      // if(error){
      //   $(".loader").hide();
      // }



      api
        .post(`/groupadmin/branch`, formData, { headers })
        .then((res) => {
          $(".loader").hide();

          // console.log("res", res.data)


          let RESGroup = localStorage.getItem("RESGroup");
          const UserPermission = JSON.parse(RESGroup);
          if (UserPermission) {
            UserPermission.customToken = res?.data?.new_token;  // Set the new token value here
            localStorage.setItem('RESGroup', JSON.stringify(UserPermission));
          }
          //  console.log("UserPermission" ,UserPermission)

          // return
          showSuccessNotification("Branch has been added succesfully.")
          history.push("/group_admin/branch_management");
          setTimeout(() => {
            window.location.reload(true)
          }, 1500);
        })
        .catch((err) => {
          $(".loader").hide();
          if (err.response) {
            showError(err.response?.data?.error?.message);
          } else if (err.request) {
            showError(err.request);
          } else {
            showError(err.message);
          }
        });
    }
  }

  const getTimeZoneOptions = () => {
    const timeZones = moment.tz.names();
    const options = timeZones.map((tz) =>
    (
      {
        value: tz,
        label: `${tz}`,
      }));
    return options;
  };

  const timeZoneOptions = getTimeZoneOptions();

  const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();


  return (
    <React.Fragment>

      {/* <Helmet> */}
      {/* {googlemapkey && ( */}
      {/* <script
            src={`https://maps.googleapis.com/maps/api/js?sensor=false&key=${"AIzaSyDt6DBxMAoqIxZEImVmLKjJF1ZFxyrLO3Q"}&libraries=places`}
            async
          ></script> */}
      {/* )} */}
      {/* </Helmet> */}
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={mainClassCustome.join(" ")}>
                    <div className="d-flex flex-stack mb-2">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h1 className="anchor fw-bolder" id="custom-form-control">
                          Add Branch
                        </h1>
                      </div>
                      <div className="d-flex flex-stack">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <GoBackButton />
                        </div>
                      </div>
                    </div>

                    <div className="py-5">
                      <div className="card card-docs mb-2">
                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                          <div className="rounded border p-10">
                            <form onSubmit={handleSubmit}>
                              <div className="mb-10">
                                <h4>Branch details.</h4>
                              </div>
                              <div className="mb-10">
                                <label className="required form-label">
                                  Branch Location
                                </label>
                                {isScriptLoaded && 
                                <PlacesAutocomplete
                                  className="form-control"
                                  value={FullAddress}
                                  onChange={setFullAddress}
                                  onSelect={handleSelect}
                                >
                                  {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                  }) => (
                                    <div className="form-group mb-4">
                                      <input
                                        {...getInputProps({
                                          placeholder: "Search Places ...",
                                          className:
                                            "location-search-input form-control",
                                        })}
                                      />
                                      <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion) => {
                                          const className = suggestion.active
                                            ? "suggestion-item--active"
                                            : "suggestion-item";
                                          const style = suggestion.active
                                            ? {
                                              backgroundColor: "#fafafa",
                                              cursor: "pointer",
                                            }
                                            : {
                                              backgroundColor: "#ffffff",
                                              cursor: "pointer",
                                            };
                                          return (
                                            <div
                                              {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                  className,
                                                  style,
                                                }
                                              )}
                                            >
                                              <span>
                                                {suggestion.description}
                                              </span>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </PlacesAutocomplete> 
                                }
                              </div>

                              <div className="mb-10">
                                <label className="required form-label">
                                  Address
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-solid"
                                  placeholder="Address"
                                  required
                                  value={address}
                                  onChange={(e) => setaddress(e.target.value)}
                                />
                              </div>
                              <div className="row col-md-12">
                                <div className="mb-10 col-md-6">
                                  <label className="required form-label">
                                    Branch Name EN
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    placeholder="Branch Name EN"
                                    required
                                    value={branch_name_EN}
                                    onChange={(e) =>
                                      setbranch_name_EN(e.target.value.replace(/\s+/g, ''))
                                    }
                                  />
                                </div>
                                <div className="mb-10 col-md-6">
                                  <label className="form-label">
                                    Branch Name AR
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    placeholder="Branch Name AR"
                                    value={branch_name_AR}
                                    onChange={(e) =>
                                      setbranch_name_AR(e.target.value.replace(/\s+/g, ''))
                                    }
                                  />
                                </div>
                                <div className="mb-10 col-md-6">
                                  <label className="form-label">
                                    Branch Name DE
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    placeholder="Branch Name DE"
                                    value={branch_name_DE}
                                    onChange={(e) =>
                                      setbranch_name_DE(e.target.value.replace(/\s+/g, ''))
                                    }
                                  />
                                </div>
                                <div className="mb-10 col-md-6">
                                  <label className="form-label">
                                    Branch Name FR
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    placeholder="Branch Name FR"
                                    value={branch_name_FR}
                                    onChange={(e) =>
                                      setbranch_name_FR(e.target.value.replace(/\s+/g, ''))
                                    }
                                  />
                                </div>
                                <div className="mb-10 col-md-6">
                                  <label className="form-label">
                                    Branch Name IT
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    placeholder="Branch Name IT"
                                    value={branch_name_IT}
                                    onChange={(e) =>
                                      setbranch_name_IT(e.target.value.replace(/\s+/g, ''))
                                    }
                                  />
                                </div>
                                <div className="mb-10 col-md-6">
                                  <label className="required form-label">
                                    Branch Sequence No
                                  </label>
                                  <input
                                    type="number"
                                    required
                                    className="form-control form-control-solid"
                                    placeholder="Branch Sequence No"
                                    value={seq_no}
                                    onChange={(e) =>
                                      setseq_no(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row col-md-12">
                                <div className="col-md-6 mb-10">
                                  <label className="required form-label">
                                    Branch Email
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control form-control-solid"
                                    placeholder="Branch email"
                                    required
                                    value={email}
                                    onChange={(e) => setemail(e.target.value)}
                                  />
                                </div>
                                <div className="col-md-6 mb-10">
                                  <label className="required form-label">
                                    Branch Phone Number
                                  </label>
                                  <input
                                    type="number"
                                    placeholder="Branch Phone Number"
                                    required
                                    name="pho_number"
                                    className="form-control form-control-solid"
                                    // maxLength="10"
                                    // minLength="10"
                                    value={phone_number}
                                    onChange={(e) =>
                                      setphone_number(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="rounded border p-10">
                                    <div className="mb-10">
                                      <h6 className="required">Language</h6>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="mb-10">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="English"
                                            required={
                                              languange.includes("Deutsch") ||
                                                languange.includes("français") ||
                                                languange.includes("Italiano")
                                                ? false
                                                : true
                                            }
                                            // checked={true}
                                            onClick={CheckHandler}
                                          />
                                          <label className="form-check-label mx-2">
                                            English
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-10">
                                          <input
                                            className="form-check-input"
                                            disabled={
                                              languange.includes("English")
                                                ? false
                                                : true
                                            }
                                            required
                                            type="radio"
                                            value="English"
                                            onChange={CheckHandlerRadio}
                                            name="fav_language"
                                          />
                                          <label className="form-check-label mx-2">
                                            Primary
                                          </label>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="mb-10">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="arabic"
                                            onClick={CheckHandler}
                                          />
                                          <label className="form-check-label mx-2">
                                            Arabic
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-10">
                                          <input
                                            className="form-check-input"
                                            disabled={
                                              languange.includes("arabic")
                                                ? false
                                                : true
                                            }
                                            value="arabic"
                                            onChange={CheckHandlerRadio}
                                            type="radio"
                                            name="fav_language"
                                          />
                                          <label className="form-check-label mx-2">
                                            Primary
                                          </label>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="mb-10">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="Deutsch"
                                            onClick={CheckHandler}
                                          />
                                          <label className="form-check-label mx-2">
                                            Deutsch
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-10">
                                          <input
                                            className="form-check-input"
                                            disabled={
                                              languange.includes("Deutsch")
                                                ? false
                                                : true
                                            }
                                            value="Deutsch"
                                            onChange={CheckHandlerRadio}
                                            type="radio"
                                            name="fav_language"
                                          />
                                          <label className="form-check-label mx-2 ">
                                            Primary
                                          </label>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="mb-10">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="français"
                                            onClick={CheckHandler}
                                          />
                                          <label className="form-check-label mx-2">
                                            Français
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-10">
                                          <input
                                            className="form-check-input"
                                            disabled={
                                              languange.includes("français")
                                                ? false
                                                : true
                                            }
                                            value="français"
                                            onChange={CheckHandlerRadio}
                                            type="radio"
                                            name="fav_language"
                                          />
                                          <label className="form-check-label mx-2">
                                            Primary
                                          </label>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="mb-10">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="Italiano"
                                            onClick={CheckHandler}
                                          />
                                          <label className="form-check-label mx-2">
                                            Italiano
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="mb-10">
                                          <input
                                            className="form-check-input"
                                            disabled={
                                              languange.includes("Italiano")
                                                ? false
                                                : true
                                            }
                                            value="Italiano"
                                            onChange={CheckHandlerRadio}
                                            type="radio"
                                            name="fav_language"
                                          />
                                          <label className="form-check-label mx-2">
                                            Primary
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="rounded border p-10">
                                    {/* <div className="mb-10">
                                      <h6>VAT Number</h6>
                                    </div> */}
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="mb-10">
                                          <label className="required form-label">
                                            VAT Number
                                          </label>
                                          <input
                                            placeholder="Enter VAT number"
                                            type="text"
                                            value={VAT_number}
                                            onChange={(e) =>
                                              setVAT_number(e.target.value)
                                            }
                                            required
                                            className="form-control form-control-solid"
                                          />
                                        </div>
                                      </div>
                                      <label className="form-label mb-2">
                                        Branch Icon
                                      </label>
                                      <div className="col-md-12 mb-9">
                                        <div
                                          className="image-input image-input-outline"
                                          data-kt-image-input="true"
                                        >
                                          <img
                                            className="image-input-wrapper w-125px h-125px"
                                            src={
                                              image
                                                ? image
                                                : "/assets/media/food.png"
                                            }
                                            alt="branch_icon"
                                            height="125px"
                                            width="125px"
                                          ></img>
                                          <label
                                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                            data-kt-image-input-action="change"
                                            data-bs-toggle="tooltip"
                                            data-bs-dismiss="click"
                                          // title="Change avatar"
                                          >
                                            <i className="bi bi-pencil-fill fs-7" />
                                            <input
                                              type="file"
                                              name="avatar"
                                              accept=".png, .jpg, .jpeg"
                                              onChange={onChangePicture}
                                            />
                                          </label>
                                          {image && (
                                            <React.Fragment>
                                              <span
                                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                                data-kt-image-input-action="cancel"
                                                data-bs-toggle="tooltip"
                                                data-bs-dismiss="click"
                                              >
                                                <i
                                                  className="bi bi-x fs-2"
                                                  onClick={() => {
                                                    setImgData(null);
                                                    setimage("");
                                                  }}
                                                />
                                              </span>
                                              <span
                                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                                data-kt-image-input-action="remove"
                                                data-bs-toggle="tooltip"
                                                data-bs-dismiss="click"
                                                s
                                                title="Remove"
                                              >
                                                <i
                                                  onClick={() => {
                                                    setImgData(null);
                                                    setimage("");
                                                  }}
                                                  className="bi bi-x fs-2"
                                                />
                                              </span>
                                            </React.Fragment>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <div className="row col-md-12">
                                <div className="col-md-6 mb-10">
                                  <label className="required form-label">
                                    Manager Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    placeholder="Manager Name"
                                    required
                                    value={manager_name}
                                    onChange={(e) =>
                                      setmanager_name(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="col-md-6 mb-10">
                                  <label className="required form-label">
                                    Manager Phone Number
                                  </label>
                                  <input
                                    type="number"
                                    placeholder="Phone Number"
                                    required
                                    name="pho_number"
                                    className="filterme form-control form-control-solid"
                                    // maxLength="10"
                                    // minLength="10"
                                    value={manager_phone_number}
                                    onChange={(e) =>
                                      setmanager_phone_number(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row col-md-12">
                                <div className="col-md-6 mb-10">
                                  <label className="required form-label">
                                    Manager Email
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control form-control-solid"
                                    placeholder="Email"
                                    required
                                    value={manager_email}
                                    onChange={(e) =>
                                      setmanager_email(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="col-md-6 mb-10">
                                  <label className="required form-label">
                                    Manager Password
                                  </label>
                                  <input
                                    type="password"
                                    className="noSpace form-control form-control-solid"
                                    placeholder="Password"
                                    required
                                    value={manager_password}
                                    onChange={(e) =>
                                      setmanager_password(e.target.value)
                                    }
                                  />
                                </div>
                              </div>

                              

                              <div className="row col-md-12">
                                <div className="mb-10 col-md-6">
                                  <label className="required form-label">
                                    Branch Time Zone
                                  </label>
                                  <Select
                                    //  value={[
                                    //     { label: `${"days" ? "Day" : "Month"}` },
                                    //   ]}
                                    placeholder="Select Time Zone..."
                                    options={timeZoneOptions}
                                    // onChange={handleTimeZoneChange}
                                    onChange={(opt) => setBranchTimeZone(opt.value)}
                                  />
                                </div>
                                <div className="mb-10 col-md-6">
                                  <label className="required form-label">
                                    Branch Currency
                                  </label>
                                  <Select
                                    placeholder="Select Currency..."
                                    options={currencyOptions}
                                    onChange={(opt) => setcurrency(opt.value)}
                                  />
                                </div>
                              </div>

                              <div className="row col-md-12">
                                <div className="col-md-6 mb-10">
                                  <label className="form-label">
                                Trip Advisor Url
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    placeholder="Trip Advisor Url"
                                    value={tripadvisor_url}
                                    onChange={(e) =>
                                      settripadvisor_url(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="col-md-6 mb-10">
                                  <label className=" form-label">
                                  Google Url
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control form-control-solid"
                                    placeholder="Google Url"
                                    value={google_url}
                                    onChange={(e) =>
                                      setgoogle_url(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row col-md-12">
                                <div className="col-md-6 mb-10">
                                  <label className="form-label required">
                                    Terms and Conditions
                                  </label>
                                  <input
                                    className="form-control form-control-solid"
                                    placeholder="Select Legal Documents."
                                    // value={document}
                                    type="file"
                                    name="file"
                                    accept=".pdf"
                                    onChange={(e) => setdocument(e.target.files)}
                                  />
                                  <div
                                    className="form-text my-3 mx-2"
                                    style={{ color: "black" }}
                                  >
                                    Allowed file types: pdf.
                                  </div>
                                </div>
                                <div className="col-md-6 mb-10">
                                  <label className="form-label">PDF Menu</label>
                                  <input
                                    className="form-control form-control-solid"
                                    placeholder="Select PDF Menu."
                                    // value={document}
                                    type="file"
                                    name="file"
                                    accept=".pdf"
                                    onChange={(e) => setmenu(e.target.files)}
                                  />
                                  <div
                                    className="form-text my-3 mx-2"
                                    style={{ color: "black" }}
                                  >
                                    Allowed file types: pdf.
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 text-center">
                                <br />
                                <div
                                  className="loader"
                                  style={{ display: "none" }}
                                >
                                  <img src="/assets/loader.gif" />
                                </div>
                              </div>
                              <ErrorMessage error={error} />
                              <SubmitformButton handleSubmit={handleSubmit} onCancel={() => history.goBack()} />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}
