import moment from 'moment';
import * as XLSX from 'xlsx';


export const specialRequirementsOptions = [
      { label: "Birthday - please advise name in message", value: "Birthday - please advise name in message" },
      { label: "Farewell", value: "Farewell" },
      { label: "Anniversary", value: "Anniversary" },
      { label: "Engagement", value: "Engagement" },
      { label: "Graduation", value: "Graduation" },
      { label: "Celebration", value: "Celebration" },
      { label: "X'Mas", value: "X'Mas" },
      { label: "Wedding", value: "Wedding" },
      { label: "Highchair", value: "Highchair" },
      { label: "Pram", value: "Highchair" },
      { label: "Allergy - please specify in message", value: "Allergy - please specify in message" },
      { label: "Dietary Requirement - please specify in message", value: "Dietary Requirement - please specify in message" },
      { label: "Disability Access", value: "Disability Access" },
      { label: "Hen's Night", value: "Hen's Night" },
      { label: "Buck's Night", value: "Buck's Night" },
];

export const colourStylesHeader = {
      control: (styles) => ({
            ...styles,
            backgroundColor: "#fff",
            borderRadius: 8,
            height: 43,
            fontWeight: 500,
            // fontFamily: '"Calibri", sans-serif',
            border: "1px solid #D0D5DD",
            color: "#667085"
      }),
      placeholder: (provided) => ({
            ...provided,
            color: "#A1A5B7", // Change this to your desired color
            // fontStyle: "italic", // Optional: Add styles like italic
      }),
};

export const STAR = <span style={{ color: "red" }}> * </span>


export const handleClickCSVDownload = (data) => {

      // console.log("data" ,data)
      const formattedData = data.map(item => ({
            'Reservation ID': item.booking_id,
            'Branch': item.branch?.branch_name_EN,
            'Reservation Type': String(item.booking_type).charAt(0).toUpperCase() + String(item.booking_type).slice(1),
            'Booking Date & Time': `${moment(item?.created_at).format("DD-MM-YYYY")}, ${moment(item?.created_at).format('LT')}`,
            'Reservation  Date & Time': `${moment(item?.booking_date).format("DD-MM-YYYY")}, ${(item.booking_time)}` ,
            'Request Type': item.booking_request_type || "---",
            'Table No': item.table_number || "---",
            'Total Guests': Number(item.no_of_guests),
            'Customer Details': item.name || item.email || item.phone_number
            ? `${item.name || ""} (${item.email || ""}, ${item.phone_number || ""})`
            : "---",
            'Status': String(item.status).charAt(0).toUpperCase() + String(item.status).slice(1),
            'Special Requirements': item.special_requirements || "---",
            'Message': item.message || "---"
      }));

      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Table Reservations");

      const fileName = "Table Reservations.xlsx";
      XLSX.writeFile(workbook, fileName);
};
export const SelectTypeOptions = (lunchSlots, dinnerSlots) => {
      const options = [];
      if (lunchSlots?.length > 0) options.push({ label: "Lunch", value: "lunch" });
      if (dinnerSlots?.length > 0) options.push({ label: "Dinner", value: "dinner" });
      return options;
};


