import React, { useState, useEffect } from "react";
import GroupAdminHeader from "../../../Comman/Header";
import GroupAdminSidebar from "../../../Comman/Sidebar";
import GroupAdminUperSide from "../../../Comman/UperSide";
import api from "../../../../CommonApi/axios";
import { useParams } from "react-router-dom";
import headers from "../../../../CommonApi/headers";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./TabelResDetails.css"
import useDrawer from "../../../../context/DrawerContext";
import { renderStatusChipTableReservations } from "../../../NewTableCommon/rendderStatucChipDaynamic";

export default function TableReservationDetailsView() {
      let history = useHistory();

      const { id } = useParams();
      const [user, setuser] = useState("");

      useEffect(() => {
            api
                  .get(`/groupadmin/table_reservation/${id}`, { headers })
                  .then((res) => {
                        const options = res.data.data;
                        setuser(options);
                  })
                  .catch((err) => {
                        if (err.response) {
                              console.log(err.response?.data?.error?.message);
                        }
                  });
      }, [id]);




      const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();

      const renderDetail = (label, value) => (
            <div className="col-md-4">
                  <div className="title">
                        {label} : <span className="Name_">{value || "No Data"}</span>
                  </div>
            </div>)

      return (
            <React.Fragment>
                  <div
                        id="kt_body"
                        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
                  >
                        <div className="d-flex flex-column flex-root">
                              <div className="page d-flex flex-row flex-column-fluid">
                                    <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
                                    <div
                                          className="wrapper d-flex flex-column flex-row-fluid"
                                          id="kt_wrapper"
                                    >
                                          <GroupAdminHeader />
                                          <div
                                                className="d-flex flex-column flex-column-fluid"
                                                id="kt_content"
                                          >
                                                <div id="kt_content_container" className="mx-7">
                                                      <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                                                            <div className="row g-5 g-xl-8">
                                                                  <div className="col-xl-12 col-lg-12 col-md-12 col-lg-12 col-md-12">
                                                                        <div className="card card-xl-stretch mb-5 mb-xl-8">
                                                                              <div className="card-header pt-5 border-1">
                                                                                    <div
                                                                                          className="d-flex justify-content-end"
                                                                                          data-kt-docs-table-toolbar="base"
                                                                                    >
                                                                                          <h1
                                                                                                className="anchor fw-bolder"
                                                                                                id="custom-form-control"
                                                                                          >
                                                                                                Table Reservation Details
                                                                                          </h1>
                                                                                    </div>
                                                                                    <div className="d-flex flex-stack">
                                                                                          <div
                                                                                                className="d-flex justify-content-end mx-2"
                                                                                                data-kt-docs-table-toolbar="base"
                                                                                          >
                                                                                                <button
                                                                                                      onClick={() => history.goBack()}
                                                                                                      className="btn btn-primary"
                                                                                                      data-kt-menu-trigger="click"
                                                                                                      data-kt-menu-attach="parent"
                                                                                                      data-kt-menu-placement="bottom-end"
                                                                                                >
                                                                                                      Go Back
                                                                                                </button>
                                                                                          </div>
                                                                                    </div>
                                                                              </div>
                                                                              <div className="d-flex flex-stack mb-7">
                                                                              </div>
                                                                              <br />
                                                                              <div className="card-body pt-0">
                                                                                    <div className="rounded border p-10">
                                                                                          <div className="row my-4 col-md-12 mx-2">
                                                                                                {renderDetail("Reservation ID", user?.booking_id)}
                                                                                                {renderDetail("Branch Name", user?.branch_name)}
                                                                                                {renderDetail("Reservation Type", user?.booking_type)}
                                                                                          </div>

                                                                                          <div className="row my-4 col-md-12 mx-2">
                                                                                                {renderDetail("Booking Date & Time", `${moment(user?.created_at).format("DD-MM-YYYY")}, ${moment(user?.created_at).format('LT')}`)}
                                                                                                {renderDetail("Reservation Date & Time", `${moment(user?.booking_date).format("DD-MM-YYYY")}, ${user?.booking_time}`)}
                                                                                                {renderDetail("Total Guests", user?.no_of_guests)}

                                                                                          </div>

                                                                                          <div className="row my-4 col-md-12 mx-2">
                                                                                                {renderDetail("Request Type", user?.booking_request_type || "---")}
                                                                                                {renderDetail(
                                                                                                      "Status",
                                                                                                      user?.status && (
                                                                                                            <span style={{ textTransform: "capitalize" }}>{user.status}</span>
                                                                                                      )
                                                                                                )}
                                                                                                {renderDetail("Table No", user?.table_number || "---")}
                                                                                          </div>

                                                                                          <div className="row my-4 col-md-12 mx-2">
                                                                                                {renderDetail("Customer Name", user?.name || "---")}
                                                                                                {renderDetail("Customer Phone Number", user?.phone_number || "---")}
                                                                                                {renderDetail("Customer Email", user?.email || "---")}
                                                                                          </div>

                                                                                          <div className="row my-4 col-md-12 mx-2">
                                                                                                {renderDetail("Special Requirements", user?.special_requirements || "---")}
                                                                                                {/* {user?.message && ( */}
                                                                                                      <div className="col-md-8">
                                                                                                            <div className="title">
                                                                                                                  Message : <span className="Name_">{user.message || "---" }</span>
                                                                                                            </div>
                                                                                                      </div>
                                                                                                {/* )} */}
                                                                                          </div>
                                                                                    </div>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </main>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <GroupAdminUperSide />
                  </div>
            </React.Fragment>
      );
}
