import { BRANCHID } from "../action-types";

const getBranchDetaisById = (id) => ({
  type: BRANCHID.GET_BRANCHDETAILS_BY_ID,
  id
});


const setBranchDetaisById = (branchDetails) => ({
  type: BRANCHID.SET_BRANCHDETAILS_BY_ID,
  branchDetails,
});



export {
      getBranchDetaisById ,setBranchDetaisById 
};
