import React, { useState } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import "./TableReservationModel.css"
import useError from '../../../Comman/Error/useError';
import api from '../../../../CommonApi/axios';
import headers from '../../../../CommonApi/headers';
import { showSuccessNotification } from '../../../Comman/Swal/Swal';
import { ErrorMessage } from '../../../Comman/Error/ErrorMessage';



export default function TableCancel({ showTableCancel, handleCloseTableCancel, getTableReservationList, DataTable }) {

      const { error, showError, clearError } = useError();
      const [message, setmessage] = useState("");
      const [emailValue, setemailValue] = useState(false);



      // console.log("ID" ,ID)

      function handleTableAction(event) {
            event.preventDefault();
            clearError(null);

            if (message === "") {
                  showError("Please enter reason/message");
                  return
            }

            else {

                  let item = {
                        cancel_reason : message,
                        reservation_status : "cancelled",
                        email : emailValue
                  }

                  api
                        .post(`/groupadmin/table_reservation/${DataTable?._id}`, item, { headers })
                        .then((response) => {
                              clearError(null);
                              showSuccessNotification("Table reservation cancelled successfully");
                              getTableReservationList();
                              handleCloseTableCancel();
                              setmessage("");
                              setemailValue(false);
                        })
                        .catch((err) => {
                              // handleMenuClose();
                              showError(err?.response?.data?.error?.message);
                        });
            }
      }

      return (
            <React.Fragment>
                  <Modal
                        show={showTableCancel} onHide={() => {
                              handleCloseTableCancel()
                        }}>
                        <Modal.Body>
                              <Row>
                                    <Col className='mt-2 Redeem_' lg={12} md={12} xs={12} sm={12}>
                                          Cancel Reservation
                                    </Col>
                              </Row>
                              <Form >
                                    <Form.Group className="mb-3 mt-2" >
                                          <Form.Label className='select_table'>Reason/Message <span style={{ color: "red" }}>*</span> </Form.Label>
                                          <Form.Control
                                                as="textarea"
                                                rows={5} // Adjust the number of rows as needed
                                                placeholder="Write a Message..."
                                                value={message}
                                                onChange={(e) =>
                                                      setmessage(e.target.value)
                                                }
                                          />
                                          <div className="my-5">
                                                <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      required
                                                      checked={emailValue}
                                                      onChange={()=>setemailValue(!emailValue)}
                                                />
                                                <label className="form-check-label mx-2">Email confirmation to customer.</label>
                                          </div>
                                    </Form.Group>
                                    <ErrorMessage error={error} />
                                    <Row>
                                          <Col className='my-3' lg={6} md={6} xs={12} sm={6}>
                                                <Button type="button" className='Cancle_button' variant="outlined"
                                                      onClick={() => {
                                                            handleCloseTableCancel();
                                                            setmessage("")
                                                            clearError(null);
                                                      }}
                                                >
                                                      Cancel
                                                </Button >
                                          </Col>
                                          <Col className='my-3' lg={6} md={6} xs={12} sm={6}>
                                                <Button
                                                      onClick={handleTableAction}
                                                      type="submit" className='Aplly_button' variant="contained" >
                                                      Confirm
                                                </Button>
                                          </Col>
                                    </Row>
                              </Form>
                        </Modal.Body>
                  </Modal>
            </React.Fragment >
      )
}
