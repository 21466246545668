import React, { useEffect, useState } from 'react';
import { Popover } from '@mui/material';
import { Col, Container, Form, Row } from 'react-bootstrap';
import Select from "react-select";


const TableAreaFilter = ({ open, anchorEl, handleClose, handleFilter, Type, url, arealist }) => {
  var pathname = window.location.pathname;
  var pathnamesplits = pathname.split("/");
  var url23 = url;



  const filterData = JSON.parse(sessionStorage.getItem(`${url23}_${Type}filterData`));


  const [areafloor, setareafloor] = useState("");
  const [order_type, setorder_type] = useState("")


  useEffect(() => {

    setareafloor(filterData?.areafloor || '');
    setorder_type(filterData?.order_type || '');
    handleFilter({
      areafloor: filterData?.areafloor,
      order_type: filterData?.order_type,
    })

  }, [Type]);


  const handleSubmit = (e) => {
    e.preventDefault();
    sessionStorage.setItem(`${url23}_${Type}filterData`, JSON.stringify({ areafloor, order_type }));
    handleFilter({ areafloor, order_type });
  };


  const handleSubmitClear = () => {
    sessionStorage.removeItem(`${url23}_${Type}filterData`);
    handleFilter({});
    setorder_type("");
    setareafloor("");
  };

  useEffect(() => {
    if (Type && filterData === null) {
      handleSubmitClear();
    }
  }, [Type && filterData === null]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={() => {
        handleClose();
        if (filterData === null) {
          setorder_type("");
          setareafloor("")
        }
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          borderRadius: 3,
          width: 420,
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 35,
            height: 35,
            ml: -0.5,
            mr: 1,
          },
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 85,
            width: 13,
            height: 13,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
    >
      <Container className='mt-5 mb-3'>
        <Row >
          <Col sm={6} lg={6} md={6}>
            <h3>Filter</h3>
          </Col>
          <Col sm={6} lg={6} md={6} className="text-end">
            <h5 onClick={(e) => {
              handleSubmitClear();
            }} style={{ color: "#F25042", cursor: "pointer" }}> Clear Filters</h5>
          </Col>
        </Row>
      </Container>
      <form onSubmit={handleSubmit}>
        <Container>
          <Row className="mb-5">
            <Col sm={6} lg={6} md={6}>
              <label className="form-label fs-6">Area</label>
              <Select
                value={areafloor}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    height: '41px',
                    borderRadius: "6px",
                    width: "100%",
                    // fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif', // Corrected syntax
                  }),
                }}
                onChange={(opt) => {
                  setareafloor(opt);
                }}
                options={arealist?.length > 0 ? arealist?.map((main) => ({
                  label: main?.area_name,
                  value: main?._id
                })) : []}
                placeholder='Area Type'
              />

            </Col>

            <Col sm={6} lg={6} md={6}>
              <label className="form-label fs-6">Order Type</label>
              <Select
                value={order_type}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    height: '41px',
                    borderRadius: "6px",
                    width: "100%",
                    // fontFamily: 'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif', // Corrected syntax
                  }),
                }}
                onChange={(opt) => {
                  setorder_type(opt);
                }}
                options={[
                  { value: 'delivery', label: 'Delivery' },
                  { value: 'pickup', label: 'Pickup' },
                  { value: 'dinein', label: 'DineIn' },
                ]}
                placeholder='Order Type'
              />

            </Col>
          </Row>
          <Row className='text-center mb-4'>
            <Col sm={3} lg={3} md={3}>
            </Col>
            <Col sm={6} lg={6} md={6}>
              <button
                type="submit"
                className='btn btn-primary'
              >
                Apply Filters
              </button>
            </Col>
            <Col sm={3} lg={3} md={3}>
            </Col>
          </Row>
        </Container>
      </form>
    </Popover>
  );
};

export default TableAreaFilter;
