import React, { useState } from 'react'
// import "./TableReservationModel.css"
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { STAR } from '../../Table/AddTableReservation/common';
import { ErrorMessage } from '../../../../Comman/Error/ErrorMessage';
import useError from '../../../../Comman/Error/useError';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import api from '../../../../../CommonApi/axios';
import headers from '../../../../../CommonApi/headers';
import { showSuccessNotification } from '../../../../Comman/Swal/Swal';
import { useEffect } from 'react';


export default function ModelForTable({ show, handleCloseModel, url ,presentNumber ,editdata ,getAreaList}) {
      const { error, showError, clearError } = useError();
      const { id } = useParams();
      let history = useHistory();
      const [TableNumber, setTableNumber] = useState("");
      // const [presentNumber, setpresentNumber] = useState(11);

      // console.log("editdata" ,editdata)


      function handleTableAction(event) {
            event.preventDefault();
            clearError(null);
          
            if (url === "table_management") {
              if (TableNumber <= 0) {
                showError("Table number must be greater than 0.");
                return;
              }
              history.push(`/group_admin/branch_management/add_table/${id}/${TableNumber}/${presentNumber}`);
              return;
            }
          
            if (!TableNumber) {
              showError("Please enter area.");
              return;
            }
          
            const isEditMode = Boolean(editdata?.area_name);
            const apiUrl = isEditMode
              ? `/groupadmin/table/area/update/${editdata._id}`
              : `/groupadmin/table/area/add`;
          
            const item = { area_name: TableNumber };
            if (!isEditMode) {
              item.branch_id = id;
            }
          
            const apiMethod = isEditMode ? api.patch : api.post;
          
            apiMethod(apiUrl, item, { headers })
              .then(() => {
                showSuccessNotification(`Area has been ${isEditMode ? "edited" : "created"} successfully.`);
                handleCloseModel();
                setTableNumber("");
                showError(null);
                getAreaList();
              })
              .catch((err) => {
                showError(err.response?.data?.error?.message);
              });
          }

          useEffect(()=>{
            if(editdata){
                  setTableNumber(editdata?.area_name || "")   
            }
          },[editdata])
          

      // console.log("editdata" ,editdata)

      return (
            <>
                  <Modal
                        show={show} onHide={() => {
                              handleCloseModel();
                              setTableNumber("");
                              showError(null);
                        }}>
                        <Modal.Body>
                              <Row>
                                    <Col className='mt-2 Redeem_' lg={12} md={12} xs={12} sm={12}>
                                          {url === "table_management" ? "Create tables" : editdata?.area_name ? "Edit Area" : "Create Area"}
                                    </Col>
                              </Row>
                              <Form>
                                    <Form.Group className="mb-3 mt-2" >


                                          <Form.Group className="mb-3" >
                                                <Form.Label className='Discount_label'>
                                                      {url === "table_management" ?
                                                            "How many tables do you want to create ?" : "Area"} {STAR}

                                                </Form.Label>
                                                <Form.Control
                                                      type={url === "table_management" ? "number" : "text"}
                                                      placeholder={url === "table_management" ? "Enter Table Number" : "Enter Area"}
                                                      value={TableNumber}
                                                      onChange={(e) => {
                                                            const value = e.target.value;
                                                            
                                                            // Prevent only spaces when type is text
                                                            if (url !== "table_management" && value.trim() === "") {
                                                              setTableNumber("");
                                                            } else {
                                                              setTableNumber(value);
                                                            }
                                                          }}
                                                />
                                          </Form.Group>
                                    </Form.Group>
                                    <ErrorMessage error={error} />
                                    <Row>
                                          <Col className='my-2 mt-4' lg={6} md={6} xs={6} sm={6}>
                                                <Button type="button" className='Cancle_button' variant="outlined"
                                                      onClick={() => {
                                                            handleCloseModel();
                                                            clearError(null);
                                                            setTableNumber("");
                                                      }}
                                                >
                                                      Cancel
                                                </Button >
                                          </Col>
                                          <Col className='my-2 mt-4' lg={6} md={6} xs={6} sm={6}>
                                                <Button
                                                      onClick={handleTableAction}
                                                      type="submit" className='Aplly_button' variant="contained" >
                                                     {url === "table_management" ? "Create" : editdata?.area_name ? "Edit Area" : "Create"}
                                                </Button>
                                          </Col>
                                    </Row>
                              </Form>
                        </Modal.Body>
                  </Modal>
            </>
      )
}
