import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import GroupAdminHeader from "../../Comman/Header";
import GroupAdminSidebar from "../../Comman/Sidebar";
import GroupAdminUperSide from "../../Comman/UperSide";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import Select from "react-select"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import $ from "jquery";
import moment from 'moment';
import 'moment-timezone';
import useDrawer from "../../../context/DrawerContext";
import { showSuccessNotification } from "../../Comman/Swal/Swal";
import { applyFilterMeHandlers } from "../../Comman/Error/jqueryUtils";
import { SubmitformButton } from "../../Comman/Error/SubmitformButton";
import { ErrorMessage } from "../../Comman/Error/ErrorMessage";
import useError from "../../Comman/Error/useError";
import { currencyOptions, getCurrencyLabelDropDown, groupAdminSetup } from "../../Comman/CommonFunction/CommonFunction";
import { LoadingBackdrop } from "../../Comman/LoadingBackdrop/LoadingBackdrop";
window.jquery = window.$ = $;

export default function GroupAdminEditBranchDetails() {
  useEffect(() => {
    applyFilterMeHandlers(); // Apply the jQuery handlers
  }, []);


  const [googleMapKey, setGoogleMapKey] = useState("");
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    groupAdminSetup(setGoogleMapKey, setIsScriptLoaded);
  }, []);

  // console.log("googleMapKey", googleMapKey)
  // console.log("isScriptLoaded", isScriptLoaded)

  const { error, showError, clearError } = useError();

  const [address, setaddress] = useState("");
  const [lat, setlat] = useState("");
  const [lng, setlng] = useState("");
  const [FullAddress, setFullAddress] = useState([]);
  const [branch_name_DE, setbranch_name_DE] = useState("");
  const [branch_name_EN, setbranch_name_EN] = useState("");
  const [branch_name_FR, setbranch_name_FR] = useState("");
  const [branch_name_IT, setbranch_name_IT] = useState("");
  const [branch_name_AR, setbranch_name_AR] = useState("");
  const [document_url, setdocument_url] = useState("");
  const [is_active, setis_active] = useState(null);
  const [menu_url, setmenu_url] = useState("");
  const [VAT_number, setVAT_number] = useState("");
  const [primary_language, setprimary_language] = useState("");
  const [languange, setlanguange] = useState([]);
  const withoutDuplicates = [...new Set(languange)];
  const [imgData, setImgData] = useState(null);
  const [image, setimage] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [BranchTimeZone, setBranchTimeZone] = useState("");
  const [seq_no, setseq_no] = useState("");
  const [currency, setcurrency] = useState("");
  const [tripadvisor_url, settripadvisor_url] = useState("");
  const [google_url, setgoogle_url] = useState("");
  const [loader, setloader] = useState(false);

  const history = useHistory();
  const { id } = useParams();
  useEffect(() => {
    branchDataGet();
  }, [id]);

  function branchDataGet() {
    setloader(true);
    api.get(`/groupadmin/branch/${id}`, { headers }).then((res) => {
      const tableData = res?.data?.data?.resData;
      setloader(false);
      setaddress(tableData.address);
      setbranch_name_DE(tableData?.branch_name_DE || "");
      setbranch_name_EN(tableData?.branch_name_EN || "");
      setbranch_name_FR(tableData?.branch_name_FR || "");
      setbranch_name_IT(tableData?.branch_name_IT || "");
      setbranch_name_AR(tableData?.branch_name_AR || "");
      setlat(tableData.lat ? tableData.lat : "");
      setlng(tableData.long ? tableData.long : "");
      setmenu_url(tableData.menu_url ? tableData.menu_url : "");
      setdocument_url(tableData.doc_url || "");
      setimage(tableData.branch_icon_url ? tableData.branch_icon_url : "");
      setlanguange(tableData.languange);
      setprimary_language(tableData.primary_language);
      setVAT_number(tableData.VAT_number);
      setis_active(tableData?.is_active);
      setemail(tableData.email);
      setphone(tableData.phone_number);
      setseq_no(tableData?.seq_no);
      setcurrency({ value: tableData?.currency, label: getCurrencyLabelDropDown(tableData?.currency) })
      setBranchTimeZone({
        label: tableData?.branchTimeZone,
        value: tableData?.branchTimeZone
      })

      settripadvisor_url(tableData?.tripadvisor_url || "");
      setgoogle_url(tableData?.google_url || "");

    });
  }

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setImgData(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setimage(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const CheckHandler = (event) => {
    var permissions_array = [...languange];
    if (event.target.checked) {
      permissions_array = [...languange, event.target.value];
    } else {
      permissions_array.splice(languange.indexOf(event.target.value), 1);
    }
    setlanguange(permissions_array);
  };

  const CheckHandlerRadio = (e) => {
    setprimary_language(e.target.value);
  };

  function handleSubmit(event) {
    event.preventDefault();
    clearError(null);

    if (lat === "" && lng === "") {
      showError("Please select your branch location");
      return;
    }
    if (BranchTimeZone === "") {
      showError("Please select branch time zone");
      return;
    }
    if (currency === "") {
      showError("Please select branch currency");
      return;
    }
    if (document_url === "") {
      showError("Please upload a file for the terms and conditions.");
      return;
    }

    else {
      const formData = new FormData();

      $(".loader").show();

      formData.append("is_active", is_active);
      formData.append("phone_number", phone);
      formData.append("email", email);
      formData.append("seq_no", seq_no);
      if (address) {
        formData.append("address", address);
      }
      if (branch_name_DE) {
        formData.append("branch_name_DE", branch_name_DE);
      }
      if (branch_name_EN) {
        formData.append("branch_name_EN", branch_name_EN);
      }
      if (branch_name_FR) {
        formData.append("branch_name_FR", branch_name_FR);
      }
      if (branch_name_IT) {
        formData.append("branch_name_IT", branch_name_IT);
      }
      if (branch_name_AR) {
        formData.append("branch_name_AR", branch_name_AR);
      }

      if (currency) {
        formData.append("currency", currency?.value);
      }
      if (VAT_number) {
        formData.append("VAT_number", VAT_number);
      }
      if (document_url[0]) {
        formData.append("document", document_url[0]);
      }
      if (imgData) {
        formData.append("branch_icon", imgData);
      }
      if (primary_language) {
        formData.append("primary_language", primary_language);
      }
      if (BranchTimeZone?.value) {
        formData.append("branchTimeZone", BranchTimeZone?.value);
      }
      if (google_url) {
        formData.append("google_url", google_url);
      }
      if (tripadvisor_url) {
        formData.append("tripadvisor_url", tripadvisor_url);
      }
      formData.append("lat", String(lat));
      formData.append("long", String(lng));
      formData.append("languange", JSON.stringify(withoutDuplicates));
      if (menu_url[0]) {
        formData.append("menu", menu_url[0]);
      }

      // console.log("sdss", Object.fromEntries(formData.entries()));
      // return;

      api
        .patch(`/groupadmin/branch/${id}`, formData, { headers })
        .then((res) => {
          $(".loader").hide();
          showSuccessNotification("Branch details has been edited successfully");
          history.push("/group_admin/branch_management");
        })
        .catch((err) => {
          if (err.response) {
            $(".loader").hide();
            showError(err.response?.data?.error?.message);
          } else if (err.request) {
            showError(err.request);
          } else {
            showError(err.message);
          }
        });
    }
  }

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);

    const ll = await getLatLng(result[0]);

    const sp = value.split(",");
    let area = "";
    for (let i = 1; i < sp.length; i++) {
      area += sp[i];
    }
    setaddress(value);
    setFullAddress(value);
    // setaddress_line2(area);
    // setcoordinates(ll);
    setlat(ll.lat);
    setlng(ll.lng);
  };


  function handleRemoveFile(event, fileType) {
    event.preventDefault();

    let formData = new FormData();
    let url = "";
    let stateUpdater = null;

    switch (fileType) {
      case "menu":
        setmenu_url("");
        formData.append("menu", "");
        url = `/groupadmin/branch/removeBranchMenu/${id}`;
        stateUpdater = setmenu_url;
        break;
      case "document":
        setdocument_url("");
        formData.append("document", "");
        url = `/groupadmin/branch/removeTermscond/${id}`;
        stateUpdater = setdocument_url;
        break;
      case "image":
        setImgData(null);
        setimage("");
        formData.append("branch_icon", "");
        url = `/groupadmin/branch/removeBranchIcon/${id}`;
        stateUpdater = setimage;
        break;
      default:
        return;
    }

    api
      .patch(url, formData, { headers })
      .then((res) => {
        // showSuccessNotification(`${fileType.charAt(0).toUpperCase() + fileType.slice(1)} has been removed successfully.`);
        // window.location.reload(true);
      })
      .catch((err) => {
        if (err.response) {
          showError(err.response?.data?.error?.message);
        }
      });
  }

  const getTimeZoneOptions = () => {
    const timeZones = moment.tz.names();
    const options = timeZones.map((tz) => ({
      value: tz,
      label: `${tz}`,
    }));
    return options;
  };

  const timeZoneOptions = getTimeZoneOptions();
  const { drawerPos, handleDrawer, mainClassCustome } = useDrawer();

  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />
              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={mainClassCustome.join(" ")}>
                    <div className="d-flex flex-stack">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h1
                          className="anchor fw-bolder"
                          id="custom-form-control"
                        >
                          Edit Branch
                        </h1>
                      </div>
                      <div className="d-flex flex-stack">
                        <div
                          className="d-flex justify-content-end mx-2"
                          data-kt-docs-table-toolbar="base"
                        >
                          <button
                            onClick={() => history.goBack()}
                            className="btn btn-primary"
                            data-kt-menu-trigger="click"
                            data-kt-menu-attach="parent"
                            data-kt-menu-placement="bottom-end"
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="py-5">
                      <div className="card card-docs mb-2">
                        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                          <div className="rounded border p-10">
                            <form onSubmit={handleSubmit}>
                              <div className="row col-md-12">
                                <div className="mb-10">
                                  <h6>Branch details.</h6>
                                </div>
                                <div className="row col-md-12">
                                  <div className="col-md-6 mb-5">
                                    <label className="form-label">
                                      Branch Location
                                    </label>
                                    {isScriptLoaded &&
                                      <PlacesAutocomplete
                                        className="form-control"
                                        value={FullAddress}
                                        onChange={setFullAddress}
                                        onSelect={handleSelect}
                                      >
                                        {({
                                          getInputProps,
                                          suggestions,
                                          getSuggestionItemProps,
                                          loading,
                                        }) => (
                                          <div className="form-group mb-4">
                                            <input
                                              {...getInputProps({
                                                placeholder: "Search Places ...",
                                                className:
                                                  "location-search-input form-control",
                                              })}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                              {loading && <div>Loading...</div>}
                                              {suggestions.map((suggestion) => {
                                                const className =
                                                  suggestion.active
                                                    ? "suggestion-item--active"
                                                    : "suggestion-item";
                                                const style = suggestion.active
                                                  ? {
                                                    backgroundColor: "#fafafa",
                                                    cursor: "pointer",
                                                  }
                                                  : {
                                                    backgroundColor: "#ffffff",
                                                    cursor: "pointer",
                                                  };
                                                return (
                                                  <div
                                                    {...getSuggestionItemProps(
                                                      suggestion,
                                                      {
                                                        className,
                                                        style,
                                                      }
                                                    )}
                                                  >
                                                    <span>
                                                      {suggestion.description}
                                                    </span>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          </div>
                                        )}
                                      </PlacesAutocomplete>
                                    }
                                  </div>
                                  <div className="col-md-6 mb-10">
                                    <label className="required form-label">
                                      Branch Status
                                    </label>
                                    <div className="rounded border p-3">
                                      {/* <div className="mb-10"> */}
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        onChange={() =>
                                          setis_active(!is_active)
                                        }
                                        checked={is_active}
                                      />
                                      <label className="form-check-label mx-3">
                                        <h6>
                                          {" "}
                                          {is_active ? "Active" : "Inactive"}
                                        </h6>
                                      </label>
                                      {/* </div> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="row col-md-12">
                                  <div className="col-md-12 mb-10">
                                    <label className="required form-label">
                                      Address
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-solid"
                                      placeholder="Address"
                                      required
                                      value={address}
                                      onChange={(e) =>
                                        setaddress(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="row col-md-12">
                                  <div className="mb-10 col-md-6">
                                    <label className="required form-label">
                                      Branch Name EN
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-solid"
                                      placeholder="Branch Name En"
                                      required
                                      value={branch_name_EN}
                                      onChange={(e) =>
                                        setbranch_name_EN(e.target.value.replace(/\s+/g, ''))
                                      }
                                    />
                                  </div>
                                  <div className="mb-10 col-md-6">
                                    <label className="form-label">
                                      Branch Name AR
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-solid"
                                      placeholder="Branch Name DE"
                                      value={branch_name_AR}
                                      onChange={(e) =>
                                        setbranch_name_AR(e.target.value.replace(/\s+/g, ''))
                                      }
                                    />
                                  </div>
                                  <div className="mb-10 col-md-6">
                                    <label className="form-label">
                                      Branch Name DE
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-solid"
                                      placeholder="Branch Name DE"
                                      value={branch_name_DE}
                                      onChange={(e) =>
                                        setbranch_name_DE(e.target.value.replace(/\s+/g, ''))
                                      }
                                    />
                                  </div>

                                  <div className="mb-10 col-md-6">
                                    <label className="form-label">
                                      Branch Name FR
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-solid"
                                      placeholder="Branch Name"
                                      value={branch_name_FR}
                                      onChange={(e) =>
                                        setbranch_name_FR(e.target.value.replace(/\s+/g, ''))
                                      }
                                    />
                                  </div>
                                  <div className="mb-10 col-md-6">
                                    <label className="form-label">
                                      Branch Name IT
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-solid"
                                      placeholder="Branch Name"
                                      value={branch_name_IT}
                                      onChange={(e) =>
                                        setbranch_name_IT(e.target.value.replace(/\s+/g, ''))
                                      }
                                    />
                                  </div>
                                  <div className="mb-10 col-md-6">
                                    <label className="required form-label">
                                      Branch Sequence No
                                    </label>
                                    <input
                                      type="text"
                                      required
                                      className="filterme form-control form-control-solid"
                                      placeholder="Branch Name IT"
                                      value={seq_no}
                                      onChange={(e) =>
                                        setseq_no(e.target.value)
                                      }
                                    />


                                  </div>
                                </div>
                                <div className="row col-md-12">
                                  <div className="col-md-6 mb-10">
                                    <label className="required form-label">
                                      Branch Email
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control form-control-solid"
                                      placeholder="email"
                                      required
                                      value={email}
                                      onChange={(e) => setemail(e.target.value)}
                                    />
                                  </div>
                                  <div className="col-md-6 mb-10">
                                    <label className="required form-label">
                                      Branch Phone No
                                    </label>
                                    <input
                                      type="text"
                                      className="filterme form-control form-control-solid"
                                      placeholder="phone"
                                      required
                                      value={phone}
                                      onChange={(e) => setphone(e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="rounded border p-10">
                                      <div className="mb-10">
                                        <h6 className="required">Language</h6>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="mb-10">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              checked={languange.includes(
                                                "English"
                                              )}
                                              value="English"
                                              required={
                                                languange.includes("Deutsch") ||
                                                  languange.includes(
                                                    "français"
                                                  ) ||
                                                  languange.includes("Italiano")
                                                  ? false
                                                  : true
                                              }
                                              // checked={true}
                                              onClick={CheckHandler}
                                            />
                                            <label
                                              className="form-check-label mx-2"
                                              for="flexCheckDefault"
                                            >
                                              English
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="mb-10">
                                            <input
                                              className="form-check-input"
                                              disabled={
                                                languange.includes("English")
                                                  ? false
                                                  : true
                                              }
                                              required
                                              checked={
                                                primary_language === "English"
                                              }
                                              type="radio"
                                              value="English"
                                              onChange={CheckHandlerRadio}
                                              name="fav_language"
                                            />
                                            <label
                                              className="form-check-label mx-2"
                                              for="flexCheckDefault"
                                            >
                                              Primary
                                            </label>
                                          </div>
                                        </div>

                                        <div className="col-md-6">
                                          <div className="mb-10">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              value="arabic"
                                              checked={languange.includes(
                                                "arabic"
                                              )}
                                              onClick={CheckHandler}
                                            />
                                            <label
                                              className="form-check-label mx-2"
                                              for="flexCheckDefault"
                                            >
                                              Arabic
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="mb-10">
                                            <input
                                              className="form-check-input"
                                              disabled={
                                                languange.includes("arabic")
                                                  ? false
                                                  : true
                                              }
                                              checked={
                                                primary_language === "arabic"
                                              }
                                              value="arabic"
                                              onChange={CheckHandlerRadio}
                                              type="radio"
                                              name="fav_language"
                                            />
                                            <label
                                              className="form-check-label mx-2"
                                              for="flexCheckDefault"
                                            >
                                              Primary
                                            </label>
                                          </div>
                                        </div>

                                        <div className="col-md-6">
                                          <div className="mb-10">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              value="Deutsch"
                                              checked={languange.includes(
                                                "Deutsch"
                                              )}
                                              onClick={CheckHandler}
                                            />
                                            <label
                                              className="form-check-label mx-2"
                                              for="flexCheckDefault"
                                            >
                                              Deutsch
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="mb-10">
                                            <input
                                              className="form-check-input"
                                              disabled={
                                                languange.includes("Deutsch")
                                                  ? false
                                                  : true
                                              }
                                              checked={
                                                primary_language === "Deutsch"
                                              }
                                              value="Deutsch"
                                              onChange={CheckHandlerRadio}
                                              type="radio"
                                              name="fav_language"
                                            />
                                            <label
                                              className="form-check-label mx-2 "
                                              for="flexCheckDefault"
                                            >
                                              Primary
                                            </label>
                                          </div>
                                        </div>

                                        <div className="col-md-6">
                                          <div className="mb-10">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              value="français"
                                              checked={languange.includes(
                                                "français"
                                              )}
                                              onClick={CheckHandler}
                                            />
                                            <label
                                              className="form-check-label mx-2"
                                              for="flexCheckDefault"
                                            >
                                              Français
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="mb-10">
                                            <input
                                              className="form-check-input"
                                              disabled={
                                                languange.includes("français")
                                                  ? false
                                                  : true
                                              }
                                              checked={
                                                primary_language === "français"
                                              }
                                              value="français"
                                              onChange={CheckHandlerRadio}
                                              type="radio"
                                              name="fav_language"
                                            />
                                            <label
                                              className="form-check-label mx-2"
                                              for="flexCheckDefault"
                                            >
                                              Primary
                                            </label>
                                          </div>
                                        </div>

                                        <div className="col-md-6">
                                          <div className="mb">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              value="Italiano"
                                              checked={languange.includes(
                                                "Italiano"
                                              )}
                                              onClick={CheckHandler}
                                            />
                                            <label
                                              className="form-check-label mx-2"
                                              for="flexCheckDefault"
                                            >
                                              Italiano
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="mb">
                                            <input
                                              className="form-check-input"
                                              disabled={
                                                languange.includes("Italiano")
                                                  ? false
                                                  : true
                                              }
                                              checked={
                                                primary_language === "Italiano"
                                              }
                                              value="Italiano"
                                              onChange={CheckHandlerRadio}
                                              type="radio"
                                              name="fav_language"
                                            />
                                            <label
                                              className="form-check-label mx-2"
                                              for="flexCheckDefault"
                                            >
                                              Primary
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="rounded border p-10">
                                      {/* <div className="mb-10">
                                        <h6>VAT Number</h6>
                                      </div> */}
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="mb-10">
                                            <label
                                              for="exampleFormControlInput1"
                                              className="filterme required form-label"
                                            >
                                              VAT Number
                                            </label>
                                            <input
                                              type="text"
                                              value={VAT_number}
                                              placeholder="Enter VAT number"
                                              required
                                              onChange={(e) =>
                                                setVAT_number(e.target.value)
                                              }
                                              className="form-control form-control-solid"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-10">
                                        <label className="form-label">Branch Icon</label>
                                        <div className="col-md-12 mb-9">
                                          <div className="image-input image-input-outline" data-kt-image-input="true">
                                            <img
                                              className="image-input-wrapper w-125px h-125px"
                                              src={image || "/assets/media/food.png"}
                                              alt="icon"
                                              height="125px"
                                              width="125px"
                                            />
                                            <label
                                              className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                              data-kt-image-input-action="change"
                                              data-bs-toggle="tooltip"
                                              data-bs-dismiss="click"
                                            >
                                              <i className="bi bi-pencil-fill fs-7" />
                                              <input
                                                type="file"
                                                name="avatar"
                                                accept=".png, .jpg, .jpeg"
                                                onChange={onChangePicture}
                                              />
                                            </label>
                                            {image && (
                                              <React.Fragment>
                                                <span
                                                  className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                                  data-kt-image-input-action="cancel"
                                                  data-bs-toggle="tooltip"
                                                  data-bs-dismiss="click"
                                                >
                                                  <i className="bi bi-x fs-2" onClick={(e) => handleRemoveFile(e, "image")} />
                                                </span>
                                                <span
                                                  className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
                                                  data-kt-image-input-action="remove"
                                                  data-bs-toggle="tooltip"
                                                  data-bs-dismiss="click"
                                                  title="Remove"
                                                >
                                                  <i className="bi bi-x fs-2" onClick={(e) => handleRemoveFile(e, "image")} />
                                                </span>
                                              </React.Fragment>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row col-md-12 mt-5 mb-4">
                                  <div className="mb-3 mt-5 col-md-6">
                                    <label className="required form-label">
                                      Branch Time Zone
                                    </label>
                                    <Select
                                      value={BranchTimeZone}
                                      className="search-line"
                                      placeholder="Select..."
                                      options={timeZoneOptions}
                                      onChange={(opt) => setBranchTimeZone(opt)}
                                    />
                                  </div>
                                  <div className="mb-3 mt-5 col-md-6">
                                    <label className="required form-label">
                                      Branch Currency
                                    </label>
                                    <Select
                                      placeholder="Select Currency..."
                                      value={currency}
                                      options={currencyOptions}
                                      onChange={(opt) => setcurrency(opt)}
                                    />
                                  </div>
                                </div>


                                <div className="row col-md-12">
                                  <div className="col-md-6 mb-5">
                                    <label className="form-label">
                                      Trip Advisor Url
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-solid"
                                      placeholder="Trip Advisor Url"
                                      value={tripadvisor_url}
                                      onChange={(e) =>
                                        settripadvisor_url(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="col-md-6 mb-5">
                                    <label className=" form-label">
                                      Google Url
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-solid"
                                      placeholder="Google Url"
                                      value={google_url}
                                      onChange={(e) =>
                                        setgoogle_url(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>


                                <div className="row col-md-12 mt-4">
                                  <div className="col-md-6 mb-10">
                                    <label className="form-label required">Terms and Conditions</label>
                                    {document_url ? (
                                      <input
                                        className="form-control form-control-solid"
                                        type="text"
                                        value={`${branch_name_EN}_Termsandconditions.pdf`}
                                      />
                                    ) : (
                                      <input
                                        className="form-control form-control-solid"
                                        placeholder="Terms and Conditions."
                                        type="file"
                                        name="file"
                                        accept=".pdf"
                                        onChange={(e) => {
                                          showError(null)
                                          setdocument_url(e.target.files)
                                        }
                                        }
                                      />
                                    )}
                                    {document_url && (
                                      <div onClick={(e) => {
                                        showError(null);
                                        handleRemoveFile(e, "document")
                                      }
                                      } className="my-3 mx-2">
                                        <Link>
                                          Remove Terms and Conditions
                                        </Link>
                                      </div>
                                    )}
                                    <div className="form-text my-3 mx-2" style={{ color: "black" }}>
                                      Allowed file types: pdf.
                                    </div>
                                  </div>

                                  <div className="col-md-6 mb-10">
                                    <label className="form-label">PDF Menu</label>
                                    {menu_url ? (
                                      <input
                                        type="text"
                                        className="form-control form-control-solid"
                                        // value={menu_url[0]?.name || menu_url}
                                        value={`${branch_name_EN}_Menu.pdf`}
                                      />
                                    ) : (
                                      <input
                                        className="form-control form-control-solid"
                                        placeholder="Select PDF Menu."
                                        type="file"
                                        name="file"
                                        accept=".pdf"
                                        onChange={(e) => setmenu_url(e.target.files)}
                                      />
                                    )}
                                    {menu_url && (
                                      <div className="my-3 mx-2">
                                        <Link onClick={(e) => handleRemoveFile(e, "menu")}>
                                          Remove Menu
                                        </Link>
                                      </div>
                                    )}
                                    <div
                                      className="form-text my-3 mx-2"
                                      style={{ color: "black" }}
                                    >
                                      Allowed file types: pdf.
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 text-center">
                                <br />
                                <div
                                  className="loader"
                                  style={{ display: "none" }}
                                >
                                  <img src="/assets/loader.gif" />
                                </div>
                              </div>
                              <ErrorMessage error={error} />
                              <SubmitformButton handleSubmit={handleSubmit} onCancel={() => history.goBack()} />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>

        {loader && <LoadingBackdrop />}
        <GroupAdminUperSide />
      </div>
    </React.Fragment>
  );
}
