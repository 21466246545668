import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import "./TableReservationModel.css"
import useError from '../../../Comman/Error/useError';
import api from '../../../../CommonApi/axios';
import headers from '../../../../CommonApi/headers';
import { showSuccessTableBook } from '../../../Comman/Swal/Swal';
import { ErrorMessage } from '../../../Comman/Error/ErrorMessage';
import { colourStylesHeader, STAR } from './AddTableReservation/common';
import moment from 'moment';
import Select from "react-select";
import { showToastCommon } from '../../../Comman/Toastify/Toastify';
import { filterAndGenerateSlots } from './slotUtils';


export function TableRescheduleReservation({  showReschedule, handleRescheduleTable, getTableReservationList, DataTable }) {


      const { error, showError, clearError } = useError();
      const [MethodType, setMethodType] = useState("");
      const [guests, setguests] = useState("");
      const [value, setValue] = useState(new Date().toISOString().split("T")[0]);
      const [userTime, setUserTime] = useState(""); // State to track selected time
      const [lunchSlots, setLunchSlots] = useState([]);
      const [dinnerSlots, setDinnerSlots] = useState([]);
      const [guestsMin_Max, setguestsMin_Max] = useState([]);
      // console.log("DataTable", DataTable);

      useEffect(() => {
            if (DataTable) {
                  setMethodType(DataTable?.booking_type)
                  setguests(DataTable?.no_of_guests);
                  setValue(moment(DataTable?.booking_date).format("YYYY-MM-DD"));
                  setUserTime({ label: DataTable?.booking_time, value: DataTable?.booking_time })
            }
            // getTableReservationListTime();
      }, [DataTable])


      function handleTableAction(event) {
            event.preventDefault();
            clearError(null);


            if (guests === "") {
                  showError("Please enter number of guests.");
                  return;
            }
            else if (value === "") {
                  showError("Please select date.");
                  return;
            }
            else if (userTime === "") {
                  showError("Please select time slot.");
                  return;
            }
            else {

                  let item = {
                        no_of_guests: Number(guests),
                        booking_date: moment(value).format("YYYY-MM-DD"),
                        booking_time: userTime?.value,
                  }

                  api
                        .post(`/groupadmin/table_reservation/rescheduleReservation/${DataTable?._id}`, item, { headers })
                        .then((response) => {
                              clearError(null);
                              showSuccessTableBook("Table reservation reschedule successfully");
                              setTimeout(() => {
                                    getTableReservationList();
                              }, 1500);
                              handleRescheduleTable();
                        })
                        .catch((err) => {
                              // handleMenuClose();
                              showError(err?.response?.data?.error?.message);
                        });
            }
      }


      useEffect(() => {
            fetchBranchList()
      }, [value, DataTable?.branch_id]);


      function fetchBranchList() {
            if(DataTable?.branch_id){
            // settimeData([])
            api
                  .get(`/groupadmin/settings/table_reservation_time/table_reservation/${DataTable?.branch_id}`, { headers })
                  .then((res) => {
                        const tableData = res.data.data;
                        // settimeData(tableData);
                        console.log("tableData", tableData[0]);


                        // setbranch_mails(tableData[0]?.branch_mails || "")
                        if (tableData[0].reservation_allowed === false) {
                              showToastCommon(`Currently, we are not accepting reservations for ${DataTable?.branch?.branch_name_EN}`)

                        } else {
                              if (tableData[0]) {
                                    if (tableData[0]?.reservation_allowed === true) {
                                          // const dynamicTargetDate = moment().format("DD-MM-YYYY"); // Example: current date
                                          const { lunchSlots, dinnerSlots, guestsMin_Max } = filterAndGenerateSlots(tableData[0] ,value);
                                          // Update the state with the generated slots
                                          setLunchSlots(lunchSlots);
                                          setDinnerSlots(dinnerSlots);
                                          setguestsMin_Max(guestsMin_Max);
                                    } else {
                                          setLunchSlots([]);
                                          setDinnerSlots([]);
                                          setguestsMin_Max([]);
                                    }

                              }
                        }

                  })
                  .catch((err) => {
                        if (err.response) {
                              // settimeData([]);
                        }
                  });
            }
      }


      return (
            <React.Fragment>
                  <Modal
                        show={showReschedule} onHide={() => {
                              handleRescheduleTable()
                        }}>
                        <Modal.Body>
                              <Row>
                                    <Col className='mt-2 Redeem_' lg={12} md={12} xs={12} sm={12}>
                                          Reschedule Reservation
                                    </Col>
                              </Row>
                              <Form >
                                    <Form.Group className="mb-3 mt-2" >


                                          <Form.Group className="mb-3" >
                                                <Form.Label className='Discount_label'>Number of Guests {STAR}</Form.Label>
                                                <Form.Control type="number" placeholder="Enter Number of Guests"
                                                      value={guests}
                                                      onChange={(e) => setguests(e.target.value)}
                                                />
                                          </Form.Group>
                                    </Form.Group>
                                    <Form.Group className="mb-3" >
                                          <Form.Label className='Discount_label'>Date Selection {STAR}</Form.Label>
                                          <Form.Control
                                                type="date"
                                                name="date"
                                                value={value}
                                                onChange={(e) => {
                                                      setValue(e.target.value);
                                                      setUserTime("")
                                                }}
                                                min={new Date().toISOString().split("T")[0]}
                                          // required
                                          />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="">
                                          <Form.Label className='Discount_label'>Time Selection {STAR}</Form.Label>
                                          <Select
                                                className="search-line"
                                                placeholder="Select Time Selection"
                                                options={MethodType ? MethodType === "lunch" ? lunchSlots?.map((main) => ({
                                                      label: main,
                                                      value: main
                                                })) : dinnerSlots.map((main) => ({
                                                      label: main,
                                                      value: main
                                                })) : []}
                                                styles={colourStylesHeader}
                                                value={userTime}
                                                onChange={(opt) => {
                                                      setUserTime(opt)
                                                      // Handle special requirements selection
                                                }}
                                          />
                                    </Form.Group>
                                    <ErrorMessage error={error} />
                                    <Row>
                                          <Col className='my-2 mt-4' lg={6} md={6} xs={6} sm={6}>
                                                <Button type="button" className='Cancle_button' variant="outlined"
                                                      onClick={() => {
                                                            handleRescheduleTable();
                                                            clearError(null);
                                                      }}
                                                >
                                                      Cancel
                                                </Button >
                                          </Col>
                                          <Col className='my-2 mt-4' lg={6} md={6} xs={6} sm={6}>
                                                <Button
                                                      onClick={handleTableAction}
                                                      type="submit" className='Aplly_button' variant="contained" >
                                                      Confirm
                                                </Button>
                                          </Col>
                                    </Row>
                              </Form>
                        </Modal.Body>
                  </Modal>
            </React.Fragment>
      )
}

