import { AREALIST } from "../../actions";

const areaListReducer = (state = [], action) => {
    switch (action.type) {
        case AREALIST.CLEAR_AREALIST:
            return [];
        case AREALIST.SET_AREALIST:
            return [...action?.arealist];
        default:
            return state;
    }
}

export default areaListReducer