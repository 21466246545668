import { call, put, takeEvery } from "redux-saga/effects";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../CommonApi/axios";
import headers from "../../../CommonApi/headers";
import { AREALIST, setAreaList } from "../../actions";

function* callAreaList({id}) { 
      if(id){
        try { 
          const response = yield call(api.get, `/groupadmin/table/area/list/${id}`, { headers: headers });
          const  tableData = response?.data?.data;
          // console.log('sss' ,tableData)
          yield put(setAreaList(tableData));
        } catch (error) {
          if (error.response) {
            yield put({ type: AREALIST.CLEAR_AREALIST });
          }
        }
      }else{
        yield put({ type: AREALIST.CLEAR_AREALIST });
      }
}


export function* watchAreaList() {
  yield takeEvery(AREALIST.GET_AREALIST, callAreaList);
}

