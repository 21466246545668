import React, { useState } from 'react'
// import "./TableReservationModel.css"
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { colourStylesHeader, STAR } from '../../Table/AddTableReservation/common';
import { ErrorMessage } from '../../../../Comman/Error/ErrorMessage';
import useError from '../../../../Comman/Error/useError';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import api from '../../../../../CommonApi/axios';
import headers from '../../../../../CommonApi/headers';
import { showSuccessNotification } from '../../../../Comman/Swal/Swal';
import { useEffect } from 'react';
import Select from "react-select";


export default function ModelTableDataEdit({ showEdit, handleEditCloseModel, url, arealist,
      editdata, getBranchDetaisById }) {
      const { error, showError, clearError } = useError();
      const [no_of_chairs, setno_of_chairs] = useState("");
      const [order_type, setorder_type] = useState("");
      const [area_id, setarea_id] = useState("");

      // console.log("editdata", editdata);
      // console.log("showEdit", showEdit);
      // console.log("arealist", arealist);

      useEffect(() => {
            setno_of_chairs(editdata?.no_of_chairs || "");

            setarea_id(
                  editdata?.area_name
                        ? { label: editdata.area_name, value: editdata.area_id || "" }
                        : ""
            );

            setorder_type(
                  editdata?.order_type
                        ? {
                              label:
                                    editdata.order_type.charAt(0).toUpperCase() +
                                    editdata.order_type.slice(1).toLowerCase(),
                              value: editdata.order_type || "",
                        }
                        : ""
            );
      }, [editdata]);




      // console.log("area_id", area_id)

      function handleSubmit(event) {
            event.preventDefault();
            showError(null);


            if (order_type?.value === "") {
                  showError("Plsease select order type");
            } else if (area_id?.value === "") {
                  showError("Plsease select table area/floor ");
            }

            else if (
                  order_type?.value === "dineIn" &&
                  (!no_of_chairs || Number(no_of_chairs) <= 0)
            ) {
                  showError("Please enter a valid number of chairs for dineIn.");
                  return;
            }
            // else if (no_of_chairs === "") {
            //       showError("Plsease enter no. of chairs");
            // }

            else {

                  let item = {
                        no_of_chairs: Number(no_of_chairs),
                        area_id: area_id?.value,
                        order_type: order_type?.value
                  }

                  api
                        .patch(`/groupadmin/table/updateTable/${editdata?._id}`, item, { headers })
                        .then((res) => {

                              showSuccessNotification("Table data has been edited successfully");
                              handleEditCloseModel();
                              getBranchDetaisById();
                              // history.goBack();
                        })
                        .catch((err) => {
                              if (err.response) {
                                    showError(err.response?.data?.error?.message);
                              }
                        });
            }
      }

      return (
            <>
                  <Modal
                        show={showEdit} onHide={() => {
                              handleEditCloseModel();
                              setarea_id("");
                              setno_of_chairs("");
                              setorder_type("");
                              showError(null);
                        }}>
                        <Modal.Body>
                              <Row>
                                    <Col className='mt-2 Redeem_' lg={12} md={12} xs={12} sm={12}>
                                          Edit Table
                                    </Col>
                              </Row>
                              <Form>


                                    <Form.Group className="my-4" controlId="">
                                          <Form.Label className='Discount_label'>Order Type {STAR}</Form.Label>
                                          <Select
                                                className="search-line"
                                                placeholder="Select Order Type"
                                                options={[
                                                      { value: 'delivery', label: 'Delivery' },
                                                      { value: 'pickup', label: 'Pickup' },
                                                      { value: 'dineIn', label: 'DineIn' },
                                                ]}
                                                styles={colourStylesHeader}
                                                value={order_type}
                                                onChange={(opt) => {
                                                      setorder_type(opt);
                                                      showError(null);
                                                      setno_of_chairs(0);
                                                      // Handle special requirements selection
                                                }}
                                          />
                                    </Form.Group>



                                    <Form.Group className="my-4" controlId="">
                                          <Form.Label className='Discount_label'>Table Area/Floor {STAR}</Form.Label>
                                          <Select
                                                className="search-line"
                                                placeholder="Select Table Area/Floor"
                                                options={arealist?.length > 0 ? arealist?.map((main) => ({
                                                      label: main?.area_name,
                                                      value: main?._id
                                                })) : []}
                                                styles={colourStylesHeader}
                                                value={area_id}
                                                onChange={(opt) => {
                                                      setarea_id(opt)
                                                      // Handle special requirements selection
                                                }}
                                          />
                                    </Form.Group>

                                    <Form.Group className="my-4" >
                                          <Form.Label className='Discount_label'>
                                                No. of Chairs  {order_type?.value === "dineIn" && STAR}

                                          </Form.Label>
                                          <Form.Control
                                                type="number"
                                                disabled={order_type?.value === "dineIn" ? false : true}
                                                placeholder="Enter No. of Chairs"
                                                value={no_of_chairs}
                                                onChange={(e) => setno_of_chairs(e.target.value)}
                                          />
                                    </Form.Group>


                                    <ErrorMessage error={error} />
                                    <Row>
                                          <Col className='my-2 mt-4' lg={6} md={6} xs={6} sm={6}>
                                                <Button type="button" className='Cancle_button' variant="outlined"
                                                      onClick={() => {
                                                            handleEditCloseModel();
                                                            clearError(null);
                                                      }}
                                                >
                                                      Cancel
                                                </Button >
                                          </Col>
                                          <Col className='my-2 mt-4' lg={6} md={6} xs={6} sm={6}>
                                                <Button
                                                      onClick={handleSubmit}
                                                      type="submit" className='Aplly_button' variant="contained" >
                                                      Edit
                                                </Button>
                                          </Col>
                                    </Row>
                              </Form>
                        </Modal.Body>
                  </Modal>
            </>
      )
}
